import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdCategory,
  MdTag,
  MdHome,
  MdLocalLibrary,
  MdLock,
  MdManageAccounts,
  MdOutlineShoppingCart,
  MdPeople,
  MdPerson,
  MdSubscriptions,
  MdNotifications,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from './views/admin/default';
import NFTMarketplace from './views/admin/marketplace';
import Profile from './views/admin/profile';
import DataTables from './views/admin/dataTables';

// Auth Imports
import SignInCentered from './views/auth/signIn';
import PlansPage from './admin-user-profile/plans/PlansPage';
import AgeGroupsPage from './admin-user-profile/age-groups/AgeGroupsPage';
import CategoryPage from './admin-user-profile/categories/CategoryPage';
import ContentsPage from './admin-user-profile/content/ContentsPage';
import ForgotPassword from './views/auth/ForgotPassword';
import ForgotPasswordChangeForm from './views/auth/ForgotPasswordChangeForm';
import AdminAccounts from './views/admin/profile/components/AdminAccounts';
import SeriesPage from './admin-user-profile/series/SeriesPage';
import CoursePage from './admin-user-profile/courses/CoursePage';
import EpisodesPage from './admin-user-profile/episodes/EpisodesPage';
import VideosPage from './admin-user-profile/videos/VideosPage';
import TopicPage from './admin-user-profile/topic/TopicPage';
import AstroServicePage from './admin-user-profile/astro-services/AstroServicePage';
import NotificationsPage from './admin-user-profile/notifications/NotificationsPage';
import { NewQuestionPage } from './admin-user-profile/questionnaire/NewQuestion';
import AppUsersPage from './admin-user-profile/app-users/AppUsersPage';
import QuestionnairesPage from './admin-user-profile/questionnaire/QuestionnairesPage';
import { EditQuestionPage } from './admin-user-profile/questionnaire/EditQuestion';
import AstroAccounts from './views/admin/profile/components/AstroAccounts';
import { NewContentPage } from './admin-user-profile/content/ContentInfoPage';
import { EditContentPage } from './admin-user-profile/content/EditContentPage';
import { NewSeriesPage } from './admin-user-profile/series/NewSeriesPage';
import { EditSeriesPage } from './admin-user-profile/series/EditSeriesPage';
import { NewCoursePage } from './admin-user-profile/courses/NewCoursePage';
import { EditCoursePage } from './admin-user-profile/courses/EditCoursePage';
import { NewEpisodePage } from './admin-user-profile/episodes/EpisodeInfoPage';
import { EditEpisodePage } from './admin-user-profile/episodes/EditEpisodePage';
import { NewVideoPage } from './admin-user-profile/videos/VideoInfoPage';
import { EditVideoPage } from './admin-user-profile/videos/EditVideoPage';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: 'Series',
    layout: '/admin',
    path: '/series/new',
    showInNavbar: false,
    component: NewSeriesPage,
  },
  {
    name: 'Courses',
    layout: '/admin',
    path: '/courses/new',
    showInNavbar: false,
    component: NewCoursePage,
  },
  {
    name: 'Videos',
    layout: '/admin',
    showInNavbar: false,
    path: '/courses/:courseId/video/new',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: NewVideoPage,
  },
  {
    name: 'Videos',
    layout: '/admin',
    showInNavbar: false,
    path: '/courses/:courseId/video/:order_id',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EditVideoPage,
  },
  {
    name: 'Videos',
    layout: '/admin',
    showInNavbar: false,
    path: '/courses/:courseId/video',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: VideosPage,
  },
  {
    name: 'Episodes',
    layout: '/admin',
    showInNavbar: false,
    path: '/series/:seriesId/episode/new',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: NewEpisodePage,
  },
  {
    name: 'Episodes',
    layout: '/admin',
    showInNavbar: false,
    path: '/series/:seriesId/episode/:episodeId',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EditEpisodePage,
  },
  {
    name: 'Episodes',
    layout: '/admin',
    showInNavbar: false,
    path: '/series/:seriesId/episode',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EpisodesPage,
  },

  {
    name: 'Series',
    layout: '/admin',
    path: '/series/:seriesId',
    showInNavbar: false,
    component: EditSeriesPage,
  },
  {
    name: 'Series',
    layout: '/admin',
    path: '/series',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: SeriesPage,
  },
  {
    name: 'AstroService',
    layout: '/admin',
    path: '/astro-service',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: AstroServicePage,
  },
  {
    name: 'Courses',
    layout: '/admin',
    path: '/courses/:courseId',
    showInNavbar: false,
    component: EditCoursePage,
  },
  {
    name: 'Courses',
    layout: '/admin',
    path: '/courses',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: CoursePage,
  },
  {
    name: 'Topic',
    layout: '/admin',
    path: '/topic',
    icon: <Icon as={MdTag} width="20px" height="20px" color="inherit" />,
    component: TopicPage,
  },
  {
    name: 'Categories',
    layout: '/admin',
    path: '/categories',
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    component: CategoryPage,
  },
  {
    name: 'Age Groups',
    layout: '/admin',
    path: '/age-groups',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: AgeGroupsPage,
  },
  {
    name: 'Plans',
    layout: '/admin',
    path: '/plans',
    icon: (
      <Icon as={MdSubscriptions} width="20px" height="20px" color="inherit" />
    ),
    component: PlansPage,
  },
  {
    name: 'Notifications',
    layout: '/admin',
    path: '/notifications',
    icon: (
      <Icon as={MdNotifications} width="20px" height="20px" color="inherit" />
    ),
    component: NotificationsPage,
  },

  {
    name: 'Questionnaire',
    layout: '/admin',
    path: '/questionnaire/new',
    showInNavbar: false,
    component: NewQuestionPage,
  },
  {
    name: 'Questionnaire',
    layout: '/admin',
    path: '/questionnaire/:questionnaireId',
    showInNavbar: false,
    component: EditQuestionPage,
  },
  {
    name: 'Questionnaire',
    layout: '/admin',
    path: '/questionnaire',
    icon: (
      <Icon as={MdNotifications} width="20px" height="20px" color="inherit" />
    ),

    component: QuestionnairesPage,
  },

  {
    name: 'Contents',
    layout: '/admin',
    path: '/contents/new',
    showInNavbar: false,
    component: NewContentPage,
  },
  {
    name: 'Contents',
    layout: '/admin',
    path: '/contents/:contentId',
    showInNavbar: false,
    component: EditContentPage,
  },
  {
    name: 'Contents',
    layout: '/admin',
    path: '/contents',
    icon: (
      <Icon as={MdLocalLibrary} width="20px" height="20px" color="inherit" />
    ),
    component: ContentsPage,
  },
  {
    name: 'NFT Marketplace',
    layout: '/admin',
    path: '/nft-marketplace',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: 'App User Accounts',
    layout: '/admin',
    path: '/app-user',
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: AppUsersPage,
    secondary: true,
  },
  {
    name: 'Admin Accounts',
    layout: '/admin',
    path: '/accounts',
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: AdminAccounts,
    secondary: true,
  },
  {
    name: 'Astrologers',
    layout: '/admin',
    path: '/astrologers',
    icon: (
      <Icon
        as={MdManageAccounts}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: AstroAccounts,
    secondary: true,
  },
  {
    name: 'Data Tables',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: DataTables,
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    layout: '/auth',
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    layout: '/auth',
    path: '/reset-password/:userId/:token',
    component: ForgotPasswordChangeForm,
  },
  {
    name: 'RTL Admin',
    layout: '/rtl',
    path: '/rtl-default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
];

export default routes;
