import React, { useEffect } from 'react';
import {
  Box, Grid, Text, useColorModeValue,
} from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import Card from '../../components/card/Card';
import HTTPService from '../../services/HTTPService';
import AppUsersTable from './AppUsersTable';
import { ListAppUserService } from '../../services/AuthService';

export default function AppUsersPage() {
  const { ref, inView } = useInView();

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['app-users'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? ListAppUserService() : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid
        gap={{ base: '20px', xl: '20px' }}
      >
        <Card mb={{ base: '0px', '2xl': '20px' }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            App Users
          </Text>
          <AppUsersTable
            columnsData={[
              {
                Header: 'Name',
                Cell: (props) => `${props.row.original.first_name ?? ''} ${props.row.original.last_name ?? ''}`,
              },
              {
                Header: 'Phone Number',
                accessor: 'phone_number',
              },
            ]}
            tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
          />
          <div ref={ref} />
        </Card>
      </Grid>
    </Box>
  );
}
