import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class ColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    const { chartData, chartOptions } = this.props;

    this.setState({
      chartData,
      chartOptions,
    });
  }

  render() {
    const { chartData, chartOptions } = this.state;

    return (
      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default ColumnChart;
