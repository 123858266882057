import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { Formik } from 'formik';
import Card from '../components/card/Card';
import { ChangePasswordAdminService } from '../services/AuthService';
import CurrentUserStore from '../stores/CurrentUserStore';

function ChangePasswordAdmin() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const [isCurrentPasswordFocused, setIsCurrentPasswordFocused] = useState(false);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isConfirmNewPasswordFocused, setIsConfirmNewPasswordFocused] = useState(false);

  const handleCurrentPassword = () => setIsCurrentPasswordFocused(!isCurrentPasswordFocused);
  const handleNewPassword = () => setIsNewPasswordFocused(!isNewPasswordFocused);
  const handleConfirmNewPassword = () => setIsConfirmNewPasswordFocused(!isConfirmNewPasswordFocused);

  const [buttonColor, setButtonColor] = useState('blue');
  const [hoverButtonColor, setHoverButtonColor] = useState('brand.600');

  const [returnMessage, setReturnMessage] = useState('');

  const successMessageStyle = useMemo(() => ({
    marginTop: '4px',
    color: buttonColor,
    fontWeight: 'bold',
    fontSize: '16px',
  }), [buttonColor]);

  return (
    <Card mb="20px" align="center" p="40px">
      <Flex
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box>
          <Heading color={textColor} fontSize="36px" mb="10px">
            Change Password
          </Heading>
          <Text
            mb="26px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Change your current password
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Formik
            initialValues={{ current_password: '', new_password: '', confirm_new_password: '' }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              ChangePasswordAdminService(values)
                .then((resp) => {
                  setReturnMessage(resp.data.message || resp.data?.non_field_errors?.[0]);
                  if (resp.status < 300) {
                    setButtonColor('green');
                    setHoverButtonColor('green.600');
                    resetForm();
                    setTimeout(() => {
                      setButtonColor('blue');
                      setHoverButtonColor('brand.600');
                      setReturnMessage('');
                      CurrentUserStore.logout();
                    }, 5000);
                  } else {
                    setButtonColor('red');
                    setHoverButtonColor('red.600');
                  }
                }).catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                  setReturnMessage('Ensure the values have at least 6 characters.');
                  setButtonColor('red');
                  setHoverButtonColor('red.600');
                }).finally(() => setSubmitting(false));
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Current Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.current_password}
                      name="current_password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Min. 6 characters"
                      mb="24px"
                      size="lg"
                      type={isCurrentPasswordFocused ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={isCurrentPasswordFocused ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleCurrentPassword}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    New Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.new_password}
                      name="new_password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Min. 6 characters"
                      mb="24px"
                      size="lg"
                      type={isNewPasswordFocused ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={isNewPasswordFocused ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleNewPassword}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Confirm New Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.confirm_new_password}
                      name="confirm_new_password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Min. 6 characters"
                      mb="24px"
                      size="lg"
                      type={isConfirmNewPasswordFocused ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={isConfirmNewPasswordFocused ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleConfirmNewPassword}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    type="submit"
                    backgroundColor={buttonColor}
                    disabled={isSubmitting}
                    _hover={{ backgroundColor: hoverButtonColor }}
                    mb="24px"
                  >
                    Submit
                  </Button>
                </FormControl>
              </form>
            )}
          </Formik>
        </Flex>
      </Flex>
      {returnMessage.length > 0 && (
      <div className={`mt-4 ${buttonColor} font-semibold`} style={successMessageStyle}>
        {returnMessage}
      </div>
      )}
    </Card>
  );
}

export default ChangePasswordAdmin;
