import React, { useEffect, useMemo, useState } from 'react';

// Chakra imports
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel, Icon, Input, InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Formik } from 'formik';

// Custom components
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import 'react-datetime/css/react-datetime.css';
import { MdUpload } from 'react-icons/md';
import NFT from '../../components/card/NFT';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import ImagePreview from '../../components/ImagePreview';
import { TopicCreateService, TopicListService } from '../../services/TopicService';

function AddTopicModal({ onClose }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  const queryClient = useQueryClient();
  return (
    <Modal isOpen onClose={onClose} isCentered size="3xl">
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>Add Series</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            name: '',
            thumbnail: null,
          }}
          onSubmit={(values, { setFieldError }) => {
            TopicCreateService(values).then((resp) => {
              if (resp.status < 300) {
                queryClient.invalidateQueries({ queryKey: ['topic'] });
                queryClient.resetQueries({ queryKey: ['topic'] });
                onClose();
              }
            }).catch((uploadError) => {
              if (uploadError.code === 'ERR_BAD_REQUEST') {
                const { data } = uploadError.response;
                Object.entries(data).forEach(([field, errors]) => {
                  setFieldError(field, errors[0]);
                });
              } else {
                setFieldError('non_field_error', 'Unknown error occurred');
              }
              // eslint-disable-next-line no-console
              console.error('Error uploading New Series:', uploadError);
            });
          }}
        >
          {({
            values,
            handleSubmit,
            isValid,
            isDirty,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={errors.name}>
                <ModalBody
                  minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                  display="flex"
                  flexDirection="column"
                >
                  {!values.thumbnail && (
                  <AspectRatio ratio={1}>
                    <Dropzone
                      w={{ base: '100%' }}
                      me="36px"
                      maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                      flex={1}
                      minH={{ base: '0' }}
                      acceptedFileTypes={['image/*']}
                      setAcceptedFiles={(files) => setFieldValue('thumbnail', files[0])}
                      content={(
                        <Box>
                          <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                          <Flex justify="center" mx="auto" mb="12px">
                            <Text fontSize="xl" fontWeight="700" color={brandColor}>
                              Thumbnail
                            </Text>
                          </Flex>
                          <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                            Image files allowed
                          </Text>
                        </Box>
                                            )}
                    />
                  </AspectRatio>
                  )}
                  {values.thumbnail && <ImagePreview image={values.thumbnail} />}
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Name
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Name"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                    />
                  </InputGroup>
                  {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" me="4px" onClick={onClose}>
                    Close
                  </Button>
                  <Button colorScheme="green" me="4px" type="submit" disabled={!isValid || isDirty}>
                    Create
                  </Button>
                </ModalFooter>
              </FormControl>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default function TopicPage() {
  const { ref, inView } = useInView();
  const [isAddTopicModelOpen, setAddTopicModelOpen] = useState(false);

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['topic'],
    async ({ pageParam = 0 }) => {
      const res = await TopicListService(pageParam);
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching && !isAddTopicModelOpen) {
      fetchNextPage();
    }
  }, [inView, isFetching, isAddTopicModelOpen]);
  const finalData = useMemo(() => (data
    ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []), [data]);
  return (
    <>
      <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
        <Flex direction="column">
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="flex-end"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            <Flex
              align="center"
              ms={{ base: '24px', md: '0px' }}
              mt={{ base: '20px', md: '0px' }}
            >
              <Button
                color={textColorBrand}
                fontWeight="500"
                onClick={() => setAddTopicModelOpen(true)}
              >
                + Add Topic
              </Button>

            </Flex>
          </Flex>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
          {finalData.map((content) => (
            <NFT
              name={content.name}
              image={content.thumbnail}
              // download={`/admin/series/${content.id}`}
              // is_audio={content.is_audio}
              // is_paid={content.is_paid}
            />
          ))}
          <div ref={ref} />
        </SimpleGrid>
      </Box>
      {isAddTopicModelOpen && <AddTopicModal onClose={() => setAddTopicModelOpen(false)} />}
    </>
  );
}
