import React from 'react';
import ReactApexChart from 'react-apexcharts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    const { chartData, chartOptions } = this.props;

    this.setState({
      chartData,
      chartOptions,
    });
  }

  render() {
    const { chartData, chartOptions } = this.state;

    return (
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="line"
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChart;
