import React, { useEffect, useState } from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Grid, Icon,
  Input,
  InputGroup, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  useInfiniteQuery, useQueryClient,
} from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

import { Formik } from 'formik';
import { MdUpload } from 'react-icons/md';
import Datetime from 'react-datetime';
import Card from '../../components/card/Card';
import HTTPService from '../../services/HTTPService';
import NotificationsTable from './NotificationsTable';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import ImagePreview from '../../components/ImagePreview';
import { NotificationCreateService, NotificationList } from '../../services/NotificationService';

function CreateNotificationModal({ onClose }) {
  const textColor = useColorModeValue('navy.700', 'white');

  const queryClient = useQueryClient();
  const brandColor = useColorModeValue('brand.500', 'white');

  return (
    <Modal isOpen onClose={onClose} isCentered size="3xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>

        <Formik
          initialValues={{
            title: '',
            description: '',
            thumbnail: null,
            send_at: null,
          }}
          onSubmit={(values, { resetForm, setFieldError }) => {
            NotificationCreateService({ ...values, send_at: values.send_at ? values.send_at.toISOString() : null })
              .then((resp) => {
                if (resp.status < 300) {
                  queryClient.invalidateQueries({ queryKey: ['notifications'] });
                  queryClient.resetQueries({ queryKey: ['notifications'] });
                  onClose();
                  resetForm();
                }
              }).catch((uploadError) => {
                if (uploadError.code === 'ERR_BAD_REQUEST') {
                  const { data } = uploadError.response;
                  Object.entries(data).forEach(([field, errors]) => {
                    setFieldError(field, errors[0]);
                  });
                } else {
                  setFieldError('non_field_error', 'Unknown error occurred');
                }
                // eslint-disable-next-line no-console
                console.error('Error creating notification:', uploadError);
              }).finally(() => {

              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
          }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1, overflow: 'auto' }}>

              <ModalHeader>Add New Notification</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                display="flex"
                flexDirection="column"
              >
                {!values.thumbnail && (
                <AspectRatio ratio={1}>
                  <Dropzone
                    w={{ base: '100%' }}
                    me="36px"
                    maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                    flex={1}
                    minH={{ base: '0' }}
                    acceptedFileTypes={['image/*']}
                    setAcceptedFiles={(files) => setFieldValue('thumbnail', files[0])}
                    content={(
                      <Box>
                        <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                        <Flex justify="center" mx="auto" mb="12px">
                          <Text fontSize="xl" fontWeight="700" color={brandColor}>
                            Thumbnail
                          </Text>
                        </Flex>
                        <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                          Image files allowed
                        </Text>
                      </Box>
                                            )}
                  />
                </AspectRatio>
                )}
                {values.thumbnail && <ImagePreview image={values.thumbnail} />}
                <FormControl isInvalid={errors.title} pb="24px" isRequired>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Name
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.title}
                      name="title"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Title"
                      size="lg"
                      type="text"
                      variant="auth"
                    />
                  </InputGroup>
                  {errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.description} pb="24px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Description
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.description}
                      name="description"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Title"
                      size="lg"
                      type="text"
                      variant="auth"
                    />
                  </InputGroup>
                  {errors.title && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.send_at} pb="24px">
                  <FormLabel
                    ms="4px"
                    mt="24px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Sending Date
                  </FormLabel>
                  <Datetime
                    name="send_at"
                    value={values.send_at ?? new Date()}
                    timeFormat="hh:mm A"
                    dateFormat="DD-MM-YYYY"
                    closeOnSelect
                    inputProps={{ style: { border: '1px solid #e8ebf8' } }}
                    onChange={(dateFromValue) => {
                      setFieldValue('send_at', dateFromValue);
                    }}
                  />
                </FormControl>
                <FormControl isInvalid={errors.non_field_error}>
                  {errors.non_field_error && <FormErrorMessage>{errors.non_field_error}</FormErrorMessage>}
                </FormControl>

              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" me="4px" onClick={onClose}>
                  Close
                </Button>
                <Button colorScheme="green" me="4px" type="submit" disabled={!values.title}>
                  Create
                </Button>
              </ModalFooter>
            </form>

          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

function Image({ cell }) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img style={{ height: 32, width: 'auto' }} src={cell.value} />;
}

export default function NotificationsPage() {
  const { ref, inView } = useInView();
  const [isAddNotificationModelOpen, setIsAddNotificationModelOpen] = useState(false);

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['notifications'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? NotificationList() : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column">
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="flex-end"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            <Flex
              align="center"
              ms={{ base: '24px', md: '0px' }}
              mt={{ base: '20px', md: '0px' }}
            >
              <Button
                color={textColorBrand}
                fontWeight="500"
                onClick={() => setIsAddNotificationModelOpen(true)}
              >
                + New Notification
              </Button>

            </Flex>
          </Flex>
        </Flex>
        <Grid
          gap={{ base: '20px', xl: '20px' }}
        >
          <Card mb={{ base: '0px', '2xl': '20px' }}>
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              Notifications
            </Text>
            <NotificationsTable
              columnsData={[
                {
                  Header: 'Image',
                  accessor: 'thumbnail',
                  Cell: Image,
                },
                {
                  Header: 'Title',
                  accessor: 'title',
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                },
              ]}
              tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
            />
            <div ref={ref} />
          </Card>
        </Grid>
      </Box>
      {isAddNotificationModelOpen && <CreateNotificationModal onClose={() => setIsAddNotificationModelOpen(false)} />}

    </>
  );
}
