import React, { useEffect, useState } from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Grid, Icon,
  Input,
  InputGroup, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useColorModeValue,

} from '@chakra-ui/react';
import {
  useInfiniteQuery, useQueryClient,
} from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { MdUpload, MdDeleteOutline } from 'react-icons/md';
import Card from '../../components/card/Card';
import HTTPService from '../../services/HTTPService';
import AstroServiceTable from './AstroServiceTable';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import ImagePreview from '../../components/ImagePreview';
import { AstroServiceCreateService, AstroServiceList } from '../../services/AstroServiceService';

function CreateAstroServiceModal({ onClose }) {
  const textColor = useColorModeValue('navy.700', 'white');

  const queryClient = useQueryClient();
  const brandColor = useColorModeValue('brand.500', 'white');

  return (

    <Modal isOpen onClose={onClose} size="3xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <Form
          onSubmit={(values, { resetForm }) => AstroServiceCreateService({ ...values })
            .then((resp) => {
              if (resp.status < 300) {
                queryClient.invalidateQueries({ queryKey: ['astro-service'] });
                queryClient.resetQueries({ queryKey: ['astro-service'] });
                onClose();
                resetForm();
              }
            })
            .catch(() => {
            })
            .finally(() => {})}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit, form: {
              mutators: { push },
            }, submitting, pristine, values, errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader>Add New Astro Service</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                display="flex"
                flexDirection="column"
                overflowY="auto"
              >
                {!values.thumbnail && (
                <AspectRatio ratio={4}>
                  <Field name="thumbnail" isRequired>
                    {({ input: { onChange } }) => (
                      <Dropzone
                        w={{ base: '100%' }}
                        me="36px"
                        maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                        flex={1}
                        minH={{ base: '0' }}
                        acceptedFileTypes={['image/*']}
                        onChange={({ target }) => { onChange(target.files[0]); }}
                        setAcceptedFiles={(files) => onChange(files[0])}
                        content={(
                          <Box>
                            <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                            <Flex justify="center" mx="auto" mb="12px">
                              <Text fontSize="xl" fontWeight="700" color={brandColor}>
                                Thumbnail
                              </Text>
                            </Flex>
                            <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                              Image files allowed
                            </Text>
                          </Box>
                              )}
                      />
                    )}
                  </Field>
                </AspectRatio>
                )}
                {values.thumbnail
                && <ImagePreview image={values.thumbnail} style={{ maxHeight: '240px', width: 'auto' }} />}
                <FormControl isInvalid={errors.name} pb="24px" isRequired>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Name
                  </FormLabel>
                  <InputGroup size="md">

                    <Field name="name" isRequired>
                      {(props) => (
                        <Input
                          isRequired
                          type="text"
                          fontSize="sm"
                          placeholder="Name"
                          size="lg"
                          variant="auth"
                          value={props.input.value}
                          onChange={props.input.onChange}
                          {...props}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </FormControl>
                <FormControl isInvalid={errors.price} pb="24px" isRequired>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Price (In Rupees)
                  </FormLabel>
                  <InputGroup size="md">
                    <Field name="price" isRequired>
                      {(props) => (
                        <Input
                          isRequired
                          type="number"
                          fontSize="sm"
                          placeholder="Price"
                          size="lg"
                          variant="auth"
                          value={props.input.value}
                          onChange={props.input.onChange}
                          {...props}
                        />
                      )}
                    </Field>
                  </InputGroup>
                  {errors.price && <FormErrorMessage>{errors.price}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.astro_service_question_mapping} pb="24px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Questions
                  </FormLabel>
                  <FieldArray name="astro_service_question_mapping">
                    {({ fields }) => fields.map((name, index) => (
                      <Flex key={index} mb="20px" minWidth="max-content" alignItems="center" gap="2">
                        <Field
                          name={`${name}`}
                          isRequired
                          key={index}
                        >
                          {(props) => (
                            <Input
                              key={index}
                              isRequired
                              type="text"
                              fontSize="sm"
                              placeholder="Question"
                              size="lg"
                              variant="auth"
                              value={props.input.value}
                              onChange={props.input.onChange}
                              {...props}
                            />
                          )}
                        </Field>
                        <Spacer />
                        <MdDeleteOutline
                          onClick={() => {
                            fields.remove(index);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Flex>
                    ))}
                  </FieldArray>
                  {errors.astro_service_question_mapping
                  && <FormErrorMessage>{errors.astro_service_question_mapping}</FormErrorMessage>}
                </FormControl>

                <div className="buttons">
                  <Button
                    colorScheme="blue"
                    me="4px"
                    onClick={() => push('astro_service_question_mapping', undefined)}
                  >
                    Add Question
                  </Button>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" me="4px" onClick={onClose}>
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  isLoading={submitting}
                  disabled={(!values.thumbnail)
                  || (submitting || pristine)}
                >
                  Create
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>

  );
}

function Image({ cell }) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img style={{ height: 32, width: 'auto' }} src={cell.value} />;
}

export default function AstroServicePage() {
  const { ref, inView } = useInView();
  const [isAddAstroServiceModelOpen, setIsAddAstroServiceModelOpen] = useState(false);

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['astro-service'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? AstroServiceList() : HTTPService.get(pageParam));
      return res.data;
    },
    async ({ pageParam = 0 }) => {
      let res;
      if (pageParam === 0) {
        res = await AstroServiceList();
      } else {
        // For subsequent pages, use HTTPService.get
        res = await HTTPService.get(pageParam);
      }
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column">
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="flex-end"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            <Flex
              align="center"
              ms={{ base: '24px', md: '0px' }}
              mt={{ base: '20px', md: '0px' }}
            >
              <Button
                color={textColorBrand}
                fontWeight="500"
                onClick={() => setIsAddAstroServiceModelOpen(true)}
              >
                + New Astro Service
              </Button>

            </Flex>
          </Flex>
        </Flex>
        <Grid
          gap={{ base: '20px', xl: '20px' }}
        >
          <Card mb={{ base: '0px', '2xl': '20px' }}>
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              Astro-services
            </Text>
            <AstroServiceTable
              columnsData={[
                {
                  Header: 'IMAGE',
                  accessor: 'thumbnail',
                  Cell: Image,
                },
                {
                  Header: 'NAME',
                  accessor: 'name',
                },
                {
                  Header: 'PRICE',
                  accessor: 'price',
                },
                {
                  Header: 'EDIT',
                  accessor: 'edit',
                },
              ]}
              tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
            />
            <div ref={ref} />
          </Card>
        </Grid>
      </Box>
      {isAddAstroServiceModelOpen && <CreateAstroServiceModal onClose={() => setIsAddAstroServiceModelOpen(false)} />}

    </>
  );
}
