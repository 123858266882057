// Chakra imports
import {
  Portal, Box, useDisclosure,
} from '@chakra-ui/react';
// Layout components
import React, { useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../routes';
import { SidebarContext } from '../../contexts/SidebarContext';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/NavbarAdmin';

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => window.location.pathname !== '/admin/full-screen-maps';
  const getActiveRoute = (activeRouteItems) => {
    const activeRoute = 'Default Brand Text';
    for (let i = 0; i < activeRouteItems.length; i += 1) {
      if (activeRouteItems[i].collapse) {
        const collapseActiveRoute = getActiveRoute(activeRouteItems[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (activeRouteItems[i].category) {
        const categoryActiveRoute = getActiveRoute(activeRouteItems[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else if (window.location.href.indexOf(activeRouteItems[i].layout + activeRouteItems[i].path) !== -1) {
        return activeRouteItems[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (activeRoutes) => {
    const activeNavbar = false;
    for (let i = 0; i < activeRoutes.length; i += 1) {
      if (activeRoutes[i].collapse) {
        const collapseActiveNavbar = getActiveNavbar(activeRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (activeRoutes[i].category) {
        const categoryActiveNavbar = getActiveNavbar(activeRoutes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else if (window.location.href.indexOf(activeRoutes[i].layout + activeRoutes[i].path) !== -1) {
        return activeRoutes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routeItems) => {
    const activeNavbar = false;
    for (let i = 0; i < routeItems.length; i += 1) {
      if (routeItems[i].collapse) {
        const collapseActiveNavbar = getActiveNavbarText(routeItems[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routeItems[i].category) {
        const categoryActiveNavbar = getActiveNavbarText(routeItems[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else if (window.location.href.indexOf(routeItems[i].layout + routeItems[i].path) !== -1) {
        return routeItems[i].messageNavbar;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routeItems) => routeItems.map((prop) => {
    if (prop.layout === '/admin') {
      return <Route path={prop.layout + prop.path} component={prop.component} key={prop.layout + prop.path} />;
    }
    if (prop.collapse) {
      return getRoutes(prop.items);
    }
    if (prop.category) {
      return getRoutes(prop.items);
    }
    return null;
  });
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  document.documentElement.dir = 'ltr';
  const providerValues = useMemo(() => ({
    toggleSidebar,
    setToggleSidebar,
  }), [toggleSidebar, setToggleSidebar]);
  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={providerValues}
        >
          <Sidebar
            routes={routes.filter(({ layout, showInNavbar }) => layout === '/admin' && (showInNavbar ?? true))}
            display="none"
            {...rest}
          />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText="Horizon UI Dashboard PRO"
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

            {getRoute() ? (
              <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" minH="100vh" pt="50px">
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/" to="/admin/default" />
                </Switch>
              </Box>
            ) : null}
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
