import HTTPService from './HTTPService';

export function PublishQuestionnaire(id) {
  return HTTPService.post(`internal/api/master-data/questionnaire/${id}/publish/`);
}

export function CreateQuestionnaire({
  question, is_draft, gender, age_group, relationship_status, goal,
}) {
  return HTTPService.post('internal/api/master-data/questionnaire/', {
    question, gender, age_group, relationship_status, goal,
  })
    .then((resp) => {
      const obj = resp.data;
      if (!is_draft) {
        return PublishQuestionnaire(obj.id);
      }
      return resp;
    });
}

export function UpdateQuestionnaire({
  id,
  question, is_draft, gender, age_group, relationship_status, goal,
}) {
  return HTTPService.put(`internal/api/master-data/questionnaire/${id}/`, {
    question, gender, age_group, relationship_status, goal,
  }).then((resp) => {
    const obj = resp.data;
    if (!is_draft) {
      return PublishQuestionnaire(obj.id);
    }
    return resp;
  });
}

export function ListQuestionnaire() {
  return HTTPService.get('internal/api/master-data/questionnaire/', {
  });
}

export function DeleteQuestionnaire(id) {
  return HTTPService.delete(`internal/api/master-data/questionnaire/${id}/`);
}

export function RetrieveQuestionnaire(id) {
  return HTTPService.get(`internal/api/master-data/questionnaire/${id}/`);
}
