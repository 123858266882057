import React, { useMemo } from 'react';
import { Image } from '@chakra-ui/react';

export default function ImagePreview({ image, ...props }) {
  const imageUrl = useMemo(() => {
    if (!image) {
      return undefined;
    }
    if (typeof image === 'string') {
      return image;
    }
    return URL.createObjectURL(image);
  }, [image]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <Image src={imageUrl} {...props} />;
}
