import React, { useState, useEffect } from 'react';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel, Grid,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
// Assets
import { Formik } from 'formik';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { CreateAdminSerivce, ListAdminService } from '../../../../../services/AuthService';
import Card from '../../../../../components/card/Card';
import HTTPService from '../../../../../services/HTTPService';
import AdminTable from './AdminTable';

function CreateAdminAccountModal({ onClose }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';

  const queryClient = useQueryClient();

  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [showPass, setShowPass] = useState(false);
  const handlePassClick = () => setShowPass(!showPass);

  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const handleConfirmPassClick = () => setShowConfirmPass(!showConfirmPass);

  return (
    <Modal isOpen onClose={onClose} isCentered size="3xl">
      <ModalOverlay />
      <Formik
        initialValues={{
          first_name: '', last_name: '', email: '', phone_number: '', password: '', confirm_password: '',
        }}
        onSubmit={(values, { resetForm, setFieldError }) => {
          CreateAdminSerivce(values)
            .then((resp) => {
              if (values.password.length < 6) {
                setFieldError('password', 'Password must be at least 6 characters long');
                return;
              }

              if (values.password !== values.confirm_password) {
                setFieldError('confirm_password', 'Passwords do not match');
                return;
              }
              if (resp.status < 300) {
                queryClient.invalidateQueries({ queryKey: ['admin'] });
                queryClient.resetQueries({ queryKey: ['admin'] });
                onClose();
                resetForm();
              }
            }).catch((uploadError) => {
              if (uploadError.code === 'ERR_BAD_REQUEST') {
                const { data } = uploadError.response;
                Object.entries(data).forEach(([field, errors]) => {
                  setFieldError(field, errors[0]);
                });
                return;
              }
              setFieldError('non_field_error', 'Unknown error occurred');

              // eslint-disable-next-line no-console
              console.error('Error uploading New Admin Account:', uploadError);
            }).finally(() => {

            });
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>

            <ModalContent>
              <ModalHeader>Add New Account</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                display="flex"
                flexDirection="column"
              >

                <FormControl isInvalid={errors.name} pb="24px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    First Name
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    value={values.first_name}
                    name="first_name"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="first_name"
                    onBlur={handleBlur}
                    placeholder="Enter your first name"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Last Name
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    value={values.last_name}
                    name="last_name"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="last_name"
                    onBlur={handleBlur}
                    placeholder="Enter your last name"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    isRequired
                    value={values.email}
                    name="email"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="email"
                    onBlur={handleBlur}
                    placeholder="Enter your email"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Phone Number
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    onChange={(event) => {
                      // Get the input value
                      let phoneNumber = event.target.value;

                      // Check if it exceeds 13 digits, and truncate if necessary
                      if (phoneNumber.length > 13) {
                        phoneNumber = phoneNumber.slice(0, 13);
                      }

                      // Update the value in the form
                      handleChange({
                        target: {
                          name: 'phone_number',
                          value: phoneNumber,
                        },
                      });
                    }}
                    isRequired
                    value={values.phone_number}
                    name="phone_number"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="phone_number"
                    onBlur={handleBlur}
                    placeholder="Enter Your Phone Number"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="24px"
                      size="lg"
                      type={showPass ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={showPass ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handlePassClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Confirm Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.confirm_password}
                      name="confirm_password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Confirm your password"
                      mb="24px"
                      size="lg"
                      type={showConfirmPass ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={showConfirmPass ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleConfirmPassClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.non_field_error}>
                  {errors.non_field_error && <FormErrorMessage>{errors.non_field_error}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.email}>
                  {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.phone_number}>
                  {errors.phone_number && <FormErrorMessage>{errors.phone_number}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.password}>
                  {errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.confirm_password}>
                  {errors.confirm_password && <FormErrorMessage>{errors.confirm_password}</FormErrorMessage>}
                </FormControl>

              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" me="4px" onClick={onClose}>
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!values.email || !values.password || !values.phone_number || !values.confirm_password}
                >
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>

        )}
      </Formik>
    </Modal>
  );
}

function AdminAccounts() {
  const { ref, inView } = useInView();
  const [isAddAccountModalOpen, setAddAccountModalOpen] = useState(false);

  const textColorBrand = useColorModeValue('brand.500', 'white');

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['admin'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? ListAdminService() : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column">
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="flex-end"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            <Flex
              align="center"
              ms={{ base: '24px', md: '0px' }}
              mt={{ base: '20px', md: '0px' }}
            >
              <Button
                color={textColorBrand}
                fontWeight="500"
                onClick={() => setAddAccountModalOpen(true)}
              >
                + Add Account
              </Button>

            </Flex>
          </Flex>
        </Flex>
        <Grid
          gap={{ base: '20px', xl: '20px' }}
        >
          <Card mb={{ base: '0px', '2xl': '20px' }}>
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              All Admin Accounts
            </Text>
            <AdminTable
              columnsData={[
                {
                  Header: 'EMAIL',
                  accessor: 'email',
                },
                {
                  Header: 'PHONE NUMBER',
                  accessor: 'phone_number',
                },
                {
                  Header: 'EDIT',
                  accessor: 'edit',
                },
              ]}
              tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
            />
            <div ref={ref} />
          </Card>
        </Grid>
      </Box>

      {isAddAccountModalOpen && <CreateAdminAccountModal onClose={() => setAddAccountModalOpen(false)} />}
    </>
  );
}

export default AdminAccounts;
