import axios from 'axios';
import HTTPService from './HTTPService';
import { FileUploadUrlService } from './ContentService';

export function AstroServiceList() {
  return HTTPService.get('/internal/api/v1/astro/service');
}

export function AstroServiceCreateService({
  name, price, thumbnail, astro_service_question_mapping,
}) {
  return FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: thumbnail },
    )
      .then(() => ({ url, key: fields.key })))
    .then(async ({ key: thumbnailKey }) => {
      try {
        const result = await HTTPService.post('internal/api/v1/astro/service', {
          name,
          price,
          thumbnail: thumbnailKey,
        });

        await Promise.all((astro_service_question_mapping ?? []).map(async (question) => {
          const data = {
            question,
            question_type: 'text',
          };
          await HTTPService.post(`internal/api/v1/astro/service/${result.data.id}/questions`, data);
        }));

        return result;
      } catch (error) {
        console.log('Error creating AstroService:', error);
        throw error;
      }
    });
}

export async function AstroServiceUpdateService({
  name, price, astro_service_question_mapping, serviceId,
}) {
  try {
    const response = await HTTPService.patch(`internal/api/v1/astro/service/${serviceId}`, {
      name,
      price,
    });

    const { data: questionsInDB } = await HTTPService.get(`internal/api/v1/astro/service/${serviceId}/questions`);
    const existingIds = questionsInDB.map((question) => question.id);
    const mappingIds = astro_service_question_mapping.map((question) => question.id);

    const questionsToDelete = existingIds.filter((id) => !mappingIds.includes(id));

    await Promise.all(questionsToDelete.map(async (id) => {
      try {
        await HTTPService.delete(`internal/api/v1/astro/service/${serviceId}/question/${id}`);
      } catch (error) {
        console.log('Error deleting question:', error);
        throw error;
      }
    }));

    await Promise.all(astro_service_question_mapping.map(async (question) => {
      try {
        const data = {
          question: question.question,
          question_type: 'text',
        };
        if (question.id) {
          await HTTPService.patch(`internal/api/v1/astro/service/${serviceId}/question/${question.id}`, data);
        }
      } catch (error) {
        console.log('Error updating question:', error);
        throw error;
      }
    }));

    await Promise.all(astro_service_question_mapping.filter((question) => question.id === undefined)
      .map(async (question) => {
        try {
          const data = {
            question: question.question,
            question_type: 'text',
          };
          await HTTPService.post(`internal/api/v1/astro/service/${serviceId}/questions`, data);
        } catch (error) {
          console.log('Error creating question:', error);
          throw error;
        }
      }));

    return response;
  } catch (error) {
    console.log('Error updating AstroService:', error);
    throw error;
  }
}

export function ListAstrologersService() {
  return HTTPService.get('internal/api/v1/user/astrologers/', { params: { is_active: true } });
}

export function GetAstroServiceByIdService(id) {
  return HTTPService.get(`internal/api/v1/astro/service/${id}`);
}

export function GetAstroQuestionsByServiceId(id) {
  return HTTPService.get(`internal/api/v1/astro/service/${id}/questions`);
}
