import AsyncSelect from 'react-select/async';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  Progress,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { MdUpload } from 'react-icons/md';
import {
  FileUploadUrlService,
  ProgressTracker,
} from '../../services/ContentService';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import VideoPreview from '../../components/VideoPreview';
import ImagePreview from '../../components/ImagePreview';
import { EditVideo, VideoRetrieveService } from '../../services/CoursesService';

export function Select({
  isDisabled,
  name,
  placeholder,
  loadOptions,
  isMulti,
  value,
  onChange,
  onBlur,
  ...props
}) {
  return (
    <AsyncSelect
      isDisabled={isDisabled}
      name={name}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      onChange={(v) => {
        onChange(v);
      }}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
}

export function EditVideoPage() {
  const history = useHistory();
  const { courseId, order_id } = useParams();
  const [initialData, setInitialData] = useState({});

  const textColor = useColorModeValue('navy.700', 'white');
  const [uploadProgress, setUploadProgress] = useState(0);

  const brandColor = useColorModeValue('brand.500', 'white');

  const queryClient = useQueryClient();

  useEffect(() => {
    const promises = [
      VideoRetrieveService({ courseId, order_id }).then((resp) => resp.data),
    ];
    Promise.all(promises).then(([obj]) => {
      setInitialData(obj);
    });
  }, [order_id]);

  if (!initialData.id) {
    return null;
  }

  return (
    <>
      <Box h={82} />
      <Box bgColor="white" p={8} borderRadius={8}>
        <Form
          initialValues={{ ...initialData, video: initialData.video.video }}
          onSubmit={(values, form) => {
            setUploadProgress(0);
            const { dirtyFields } = form.getState();
            const body = {};
            Object.keys(dirtyFields).forEach((key) => {
              if (dirtyFields[key]) {
                body[key] = values[key];
              }
            });
            const onUploadProgress = (pevt) => {
              setUploadProgress(Math.round((pevt.loaded / pevt.total) * 100));
            };
            const progressTracker = new ProgressTracker(onUploadProgress);
            let videoUploadPromise = Promise.resolve(null);

            if (body.video) {
              videoUploadPromise = FileUploadUrlService({
                name: body.video.name,
              }).then(({ url, fields }) => axios
                .postForm(
                  url,
                  { ...fields, file: body.video },
                  {
                    onUploadProgress: (e) => progressTracker.setProgress(1, e),
                  },
                )
                .then(() => fields.key));
            }

            let thumbnailUploadPromise = Promise.resolve(null);
            if (body.thumbnail) {
              thumbnailUploadPromise = FileUploadUrlService({
                name: body.thumbnail.name,
              }).then(({ url, fields }) => axios
                .postForm(
                  url,
                  { ...fields, file: body.thumbnail },
                  {
                    onUploadProgress: (e) => progressTracker.setProgress(1, e),
                  },
                )
                .then(() => fields.key));
            }

            return Promise.all([videoUploadPromise, thumbnailUploadPromise])
              .then(([videoKey, thumbnailKey]) => {
                if (videoKey) {
                  body.video = videoKey;
                }
                if (thumbnailKey) {
                  body.thumbnail = thumbnailKey;
                }
                return EditVideo(courseId, order_id, {
                  ...body,
                  is_draft: values.is_draft,
                });
              })
              .then((resp) => {
                if (resp.status < 300) {
                  queryClient.invalidateQueries({ queryKey: ['videos'] });
                  queryClient.resetQueries({ queryKey: ['videos'] });
                  history.push(`/admin/courses/${courseId}/video`);
                }
              });
          }}
          render={({
            handleSubmit, valid, dirty, submitting, form,
          }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1, overflow: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Field
                    name="video"
                    render={({ input }) => (
                      <div style={{ flex: 1 }}>
                        {!input.value && (
                        <AspectRatio ratio={16 / 9}>
                          <Dropzone
                            w={{ base: '100%' }}
                            me="36px"
                            maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                            flex={1}
                            minH={{ base: '0' }}
                            acceptedFileTypes={['video/*', 'audio/*']}
                            setAcceptedFiles={(files) => input.onChange(files[0])}
                            content={(
                              <Box>
                                <Icon
                                  as={MdUpload}
                                  w="80px"
                                  h="80px"
                                  color={brandColor}
                                />
                                <Flex justify="center" mx="auto" mb="12px">
                                  <Text
                                    fontSize="xl"
                                    fontWeight="700"
                                    color={brandColor}
                                  >
                                    Video/Audio File
                                  </Text>
                                </Flex>
                                <Text
                                  fontSize="sm"
                                  fontWeight="500"
                                  color="secondaryGray.500"
                                >
                                  Only Audio/Video file allowed
                                </Text>
                              </Box>
                              )}
                          />
                        </AspectRatio>
                        )}
                        {input.value && <VideoPreview video={input.value} />}
                      </div>
                    )}
                  />
                  <Field
                    name="thumbnail"
                    render={({ input }) => (
                      <div style={{ flex: 1 }}>
                        {!input.value && (
                        <AspectRatio ratio={1}>
                          <Dropzone
                            w={{ base: '100%' }}
                            me="36px"
                            maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                            flex={1}
                            minH={{ base: '0' }}
                            acceptedFileTypes={['image/*']}
                            setAcceptedFiles={(files) => input.onChange(files[0])}
                            content={(
                              <Box>
                                <Icon
                                  as={MdUpload}
                                  w="80px"
                                  h="80px"
                                  color={brandColor}
                                />
                                <Flex justify="center" mx="auto" mb="12px">
                                  <Text
                                    fontSize="xl"
                                    fontWeight="700"
                                    color={brandColor}
                                  >
                                    Thumbnail
                                  </Text>
                                </Flex>
                                <Text
                                  fontSize="sm"
                                  fontWeight="500"
                                  color="secondaryGray.500"
                                >
                                  Image files allowed
                                </Text>
                              </Box>
                              )}
                          />
                        </AspectRatio>
                        )}
                        {input.value && <ImagePreview image={input.value} />}
                      </div>
                    )}
                  />
                </div>
                <Field
                  name="title"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Title
                      </FormLabel>
                      <InputGroup size="md">
                        <Input {...input} isRequired fontSize="sm" size="lg" />
                      </InputGroup>
                      {meta.touched && meta.invalid && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <Field
                  name="order_id"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        video Id
                      </FormLabel>
                      <InputGroup size="md">
                        <Input {...input} isRequired fontSize="sm" size="lg" />
                      </InputGroup>
                      {meta.touched && meta.invalid && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <Field
                  name="information"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Affirmation
                      </FormLabel>
                      <InputGroup size="md">
                        <Input {...input} fontSize="sm" size="lg" />
                      </InputGroup>
                      {meta.touched && meta.invalid && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />

                {submitting && (
                <Flex mt="16px" gap="4px" alignItems="center">
                  <Progress
                    flex={1}
                    hasStripe
                    isAnimated
                    value={uploadProgress}
                  />
                  <Text
                    color="secondaryGray.600"
                    fontSize={{
                      base: 'sm',
                    }}
                    fontWeight="400"
                    me="14px"
                  >
                    {uploadProgress}
                    %
                  </Text>
                </Flex>
                )}
              </div>
              <div style={{ marginTop: '24px' }}>
                <Button
                  colorScheme="blue"
                  me="4px"
                  onClick={() => history.goBack(`/admin/courses/${courseId}/video`)}
                >
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={
                    !dirty
                    || !valid
                    || submitting
                    || form.getFieldState('video').dirty
                  }
                  onClick={() => {
                    form.change('is_draft', false);
                  }}
                >
                  Save and Publish
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting}
                  onClick={() => {
                    form.change('is_draft', true);
                  }}
                >
                  Save as Draft
                </Button>
              </div>
            </form>
          )}
        />
      </Box>
    </>
  );
}
