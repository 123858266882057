import React, { useEffect, useMemo } from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import 'react-datetime/css/react-datetime.css';
import { useHistory, useParams } from 'react-router-dom';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { MdEdit, MdPublish } from 'react-icons/md';
import { DeleteIcon } from '@chakra-ui/icons';
import { IoMusicalNotes, IoVideocam } from 'react-icons/io5';
import {
  VideosListService,
  VideoDeleteService,
  PublishVideo,
} from '../../services/CoursesService';
import HTTPService from '../../services/HTTPService';
import Card from '../../components/card/Card';
import NotificationsTable from '../notifications/NotificationsTable';

function DraftLabel({ row }) {
  return (
    row.original.is_draft && (
      <Tag>
        <TagLabel>IS DRAFT</TagLabel>
      </Tag>
    )
  );
}

function PublishButton({ row, queryClient, courseId }) {
  return (
    row.original.is_draft && (
      <Tooltip
        label={
          row.original.video.job_status !== 'complete'
            ? `This videos cannot be published yet since the media processing job is in
             ${row.original.video.job_status} state` : 'Publish'
        }
      >
        <span>
          <IconButton
            boxSize={6}
            isDisabled={row.original.video.job_status !== 'complete'}
            as={MdPublish}
            onClick={() => {
              PublishVideo(courseId, row.original.id).then(() => {
                queryClient.invalidateQueries({ queryKey: ['videos'] });
                queryClient.resetQueries({ queryKey: ['videos'] });
              });
            }}
          />
        </span>
      </Tooltip>
    )
  );
}
function DeleteButton({ row, queryClient, courseId }) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<DeleteIcon />}
        variant="outline"
      />
      <MenuList>
        {row.original.is_draft && (
          <MenuItem
            onClick={() => VideoDeleteService(courseId, row.original.id).then(() => {
              queryClient.invalidateQueries({ queryKey: ['videos'] });
              queryClient.resetQueries({ queryKey: ['videos'] });
            })}
          >
            Discard Draft
          </MenuItem>
        )}
        {row.original.is_draft && row.original.parent_episode && (
          <MenuItem
            onClick={() => VideoDeleteService(courseId, row.original.parent_episode).then(
              () => {
                queryClient.invalidateQueries({ queryKey: ['videos'] });
                queryClient.resetQueries({ queryKey: ['videos'] });
              },
            )}
          >
            Delete Published Version along with draft
          </MenuItem>
        )}

        {!row.original.is_draft && (
          <MenuItem
            onClick={() => VideoDeleteService(courseId, row.original.id).then(() => {
              queryClient.invalidateQueries({ queryKey: ['videos'] });
              queryClient.resetQueries({ queryKey: ['videos'] });
            })}
          >
            Delete Published Version
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

function Image({ cell }) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img style={{ height: 32, width: 'auto' }} src={cell.value} />;
}

export default function VideosPage() {
  const { courseId } = useParams();
  const { ref, inView } = useInView();
  const history = useHistory();
  const { data, isFetching, fetchNextPage } = useInfiniteQuery(
    ['videos', courseId],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0
        ? VideosListService({ courseId })
        : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  const queryClient = useQueryClient();

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const columnsData = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'thumbnail',
        Cell: Image,
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Attributes',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <HStack>
            <Icon
              transition="0.2s linear"
              w="20px"
              h="20px"
              as={row.original.video.is_audio ? IoMusicalNotes : IoVideocam}
              color="brand.500"
            />
          </HStack>
        ),
      },
      {
        id: 'draft',
        Cell: DraftLabel,
      },
      {
        id: 'publish',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <PublishButton
            row={row}
            queryClient={queryClient}
            courseId={courseId}
          />
        ),
      },
      {
        id: 'delete',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <DeleteButton
            row={row}
            queryClient={queryClient}
            courseId={courseId}
          />
        ),
      },
      {
        id: 'edit',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <Tooltip label="Edit">
            <span>
              <IconButton
                boxSize={6}
                as={MdEdit}
                onClick={() => history.push(
                  `/admin/courses/${courseId}/video/${row.original.id}`,
                )}
              />
            </span>
          </Tooltip>
        ),
      },
    ],
    [queryClient],
  );

  return (
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}
        >
          <Flex
            align="center"
            ms={{ base: '24px', md: '0px' }}
            mt={{ base: '20px', md: '0px' }}
          >
            <Button
              color={textColorBrand}
              fontWeight="500"
              onClick={() => history.push(`/admin/courses/${courseId}/video/new`)}
            >
              + Add Video
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Grid gap={{ base: '20px', xl: '20px' }}>
        <Card mb={{ base: '0px', '2xl': '20px' }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Videos
          </Text>
          <NotificationsTable
            columnsData={columnsData}
            tableData={
              data
                ? data.pages.reduce(
                  (previous, item) => previous.concat(item.results),
                  [],
                )
                : []
            }
          />
          <div ref={ref} />
        </Card>
      </Grid>
    </Box>
  );
}
