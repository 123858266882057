import axios from 'axios';
import Cookies from 'js-cookie';
import CurrentUserStore from '../stores/CurrentUserStore';

const axiosInstance = axios.create({ baseURL: `${process.env.REACT_APP_API_ENDPOINT}`, withCredentials: true });

axiosInstance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    return config;
  },
  (error) => Promise.reject(error),
);
axiosInstance.interceptors.response.use(
  // If the response is successful, return it as is
  (response) => response,
  (error) => {
    // Check if the error status code indicates an expired session or authentication failure
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Redirect the user to the login page or handle the session expiration as needed
      // For example, you can show a message to the user and then redirect them to the login page
      CurrentUserStore.logout();
      // Redirect to the login page or perform other actions
    }
    // If the error is not related to session expiration, reject it
    return Promise.reject(error);
  },
);
export default axiosInstance;
