import axios from 'axios';
import HTTPService from './HTTPService';
import { FileUploadUrlService } from './ContentService';

export function NotificationList() {
  return HTTPService.get('/internal/api/v1/notification/');
}

export function NotificationCreateService({
  title, description, thumbnail, send_at,
}) {
  const thumbnailUploadPromise = FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: thumbnail },
    )
      .then(() => ({ url, key: fields.key })));
  return Promise.all([thumbnailUploadPromise])
    .then(([{ key: thumbnailKey }]) => HTTPService.post('internal/api/v1/notification/', {
      description,
      title,
      thumbnail: thumbnailKey,
      send_at,
    }, {
    }));
}
