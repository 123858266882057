import { makeAutoObservable } from 'mobx';

class CurrentUserStore {
  isLoggedIn = false;

  isHydrated = false;

  userData = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoginStatus(status, userData) {
    this.isLoggedIn = status;
    this.isHydrated = true;
    this.userData = userData;
  }

  logout() {
    this.isLoggedIn = false;
    this.userData = null;
  }
}

export default new CurrentUserStore();
