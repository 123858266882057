import React, { useEffect, useMemo } from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tag,
  TagLabel,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import 'react-datetime/css/react-datetime.css';
import { MdPublish, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import Card from '../../components/card/Card';
import NotificationsTable from '../notifications/NotificationsTable';
import {
  PublishCourse,
  CourseDeleteService,
  CourseListService,
} from '../../services/CoursesService';

function DraftLabel({ row }) {
  return (
    row.original.is_draft && (
      <Tag>
        <TagLabel>IS DRAFT</TagLabel>
      </Tag>
    )
  );
}

function PublishButton({ row, queryClient }) {
  return (
    row.original.is_draft && (
      <Tooltip>
        <span>
          <IconButton
            boxSize={6}
            as={MdPublish}
            onClick={() => {
              PublishCourse(row.original.id).then(() => {
                queryClient.invalidateQueries({ queryKey: ['course'] });
                queryClient.resetQueries({ queryKey: ['course'] });
              });
            }}
          />
        </span>
      </Tooltip>
    )
  );
}
function DeleteButton({ row, queryClient }) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<DeleteIcon />}
        variant="outline"
      />
      <MenuList>
        {row.original.is_draft && (
          <MenuItem
            onClick={() => CourseDeleteService(row.original.id).then(() => {
              queryClient.invalidateQueries({ queryKey: ['course'] });
              queryClient.resetQueries({ queryKey: ['course'] });
            })}
          >
            Discard Draft
          </MenuItem>
        )}
        {row.original.is_draft && row.original.parent_course && (
          <MenuItem
            onClick={() => CourseDeleteService(row.original.parent_course).then(() => {
              queryClient.invalidateQueries({ queryKey: ['course'] });
              queryClient.resetQueries({ queryKey: ['course'] });
            })}
          >
            Delete Published Version along with draft
          </MenuItem>
        )}

        {!row.original.is_draft && (
          <MenuItem
            onClick={() => CourseDeleteService(row.original.id).then(() => {
              queryClient.invalidateQueries({ queryKey: ['course'] });
              queryClient.resetQueries({ queryKey: ['course'] });
            })}
          >
            Delete Published Version
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

function Image({ cell }) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img style={{ height: 32, width: 'auto' }} src={cell.value} />;
}
export default function CoursePage() {
  const { ref, inView } = useInView();
  const history = useHistory();
  const { data, isFetching, fetchNextPage } = useInfiniteQuery(
    ['course'],
    async ({ pageParam = 0 }) => {
      const res = await CourseListService(pageParam);
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  const queryClient = useQueryClient();

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const columnsData = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'thumbnail',
        Cell: Image,
      },
      {
        Header: 'Title',
        accessor: 'title',

        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <Tooltip label="videos">
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                history.push(
                  `/admin/courses/${
                    row.original.parent_course ?? row.original.id
                  }/video`,
                );
              }}
            >
              {row.original.title}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'Price',
        accessor: 'price',

        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <Tooltip label="Price">
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => history.push(
                `/admin/courses/${
                  row.original.parent_course ?? row.original.id
                }`,
              )}
            >
              {row.original.price}
            </span>
          </Tooltip>
        ),
      },
      {
        id: 'draft',
        Cell: DraftLabel,
      },

      {
        id: 'publish',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <PublishButton row={row} queryClient={queryClient} />
        ),
      },
      {
        id: 'delete',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => <DeleteButton row={row} queryClient={queryClient} />,
      },
      {
        id: 'edit',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }) => (
          <Tooltip label="Edit">
            <span>
              <IconButton
                boxSize={6}
                as={MdEdit}
                onClick={() => history.push(`/admin/courses/${row.original.id}`)}
              />
            </span>
          </Tooltip>
        ),
      },
    ],

    queryClient,
  );

  return (
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}
        >
          <Flex
            align="center"
            ms={{ base: '24px', md: '0px' }}
            mt={{ base: '20px', md: '0px' }}
          >
            <Button
              color={textColorBrand}
              fontWeight="500"
              onClick={() => history.push('/admin/courses/new')}
            >
              + Add course
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Grid gap={{ base: '20px', xl: '20px' }}>
        <Card mb={{ base: '0px', '2xl': '20px' }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Course
          </Text>
          <NotificationsTable
            columnsData={columnsData}
            tableData={
              data
                ? data.pages.reduce(
                  (previous, item) => previous.concat(item.results),
                  [],
                )
                : []
            }
          />
          <div ref={ref} />
        </Card>
      </Grid>
    </Box>
  );
}
