import { useHistory, useParams } from 'react-router-dom';
import {
  Box, Button,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import AsyncSelect from 'react-select/async';
import { Field, Form } from 'react-final-form';
import * as Yup from 'yup';
import { setIn } from 'final-form';
import {
  AgeGroupListService,
  GenderListService, GoalListService,
  RelationshipStatusListService,
} from '../../services/AuthService';
import { RetrieveQuestionnaire, UpdateQuestionnaire } from '../../services/QuestionnaireService';

function Select({
  isDisabled, name, placeholder, loadOptions, isMulti, value, onChange, onBlur, ...props
}) {
  return (
    <AsyncSelect
      isDisabled={isDisabled}
      name={name}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      onChange={(v) => {
        onChange(v);
      }}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
}

const schema = Yup.object({
  question: Yup.string().required('Required'),
}); const validate = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    return err.inner.reduce((formError, innerError) => setIn(formError, innerError.path, innerError.message), {});
  }
  return undefined;
};

function loadAgeGroupsListOptions(inputValue) {
  return AgeGroupListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: `${item.min_age} - ${item.max_age}`,
    })));
}

function loadGenderListOptions(inputValue) {
  return GenderListService(inputValue)
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));
}

function loadRelationShipListOptions(inputValue) {
  return RelationshipStatusListService(inputValue)
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));
}

function loadGoalListOptions() {
  return GoalListService()
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));
}

export function EditQuestionPage() {
  const history = useHistory();
  const { questionnaireId } = useParams();

  const [questionnaireObject, setQuestionnaireObject] = useState({});

  useEffect(() => {
    const promises = [
      loadAgeGroupsListOptions(),
      loadGoalListOptions(),
      loadGenderListOptions(),
      loadRelationShipListOptions(),
      RetrieveQuestionnaire(questionnaireId).then((resp) => resp.data),
    ];
    Promise.all(promises)
      .then(([ageGroupList, goalList, genderList, relationshipStatusList, questions]) => {
        const obj = { ...questions };
        obj.age_group = ageGroupList.filter((item) => obj.age_group.includes(item.value));
        obj.gender = genderList.filter((item) => obj.gender.includes(item.value));
        obj.relationship_status = relationshipStatusList.filter((item) => obj.relationship_status.includes(item.value));
        obj.goal = goalList.filter((item) => obj.goal.includes(item.value));
        setQuestionnaireObject(obj);
      });
    // RetrieveQuestionnaire(questionnaireId)
    //   .then((resp) => setQuestionnaireObject(resp.data));
  }, [questionnaireId]);

  const textColor = useColorModeValue('navy.700', 'white');

  const queryClient = useQueryClient();

  if (!questionnaireObject.id) {
    return null;
  }
  return (
    <>
      <Box h={82} />
      <Box bgColor="white" p={8} borderRadius={8}>
        <Form
          validate={validate}
          initialValues={questionnaireObject}
          onSubmit={(values) => UpdateQuestionnaire({
            id: questionnaireId,
            question: values.question,
            is_draft: values.is_draft,
            age_group: values.age_group?.map(({ value }) => value),
            goal: values.goal?.map(({ value }) => value),
            relationship_status: values.relationship_status?.map(({ value }) => value),
            gender: values.gender?.map(({ value }) => value),
          }).then((resp) => {
            if (resp.status < 300) {
              queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
              queryClient.resetQueries({ queryKey: ['questionnaire'] });
              history.push('/admin/questionnaire');
            }
          })}
          render={({
            handleSubmit,
            valid,
            dirty,
            submitting,
            form,
          }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1, overflow: 'auto' }}>
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Field
                  name="question"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Question
                      </FormLabel>
                      <InputGroup size="md" isRequired>
                        <Input
                          isRequired
                          fontSize="sm"
                          size="lg"
                          {...input}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="gender"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Gender
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadGenderListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="goal"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Goal
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadGoalListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="age_group"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Age Groups
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadAgeGroupsListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="relationship_status"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Relationship Status
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadRelationShipListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

              </div>
              <Box mt="12px">
                <Button colorScheme="blue" me="4px" onClick={() => history.push('/admin/questionnaire')}>
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting}
                  onClick={() => {
                    form.change('is_draft', false);
                  }}
                >
                  Publish
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting}
                  onClick={() => {
                    form.change('is_draft', true);
                  }}
                >
                  Save as Draft
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    </>
  );
}
