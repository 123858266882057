import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  useInfiniteQuery, useQueryClient,
} from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

import { Formik } from 'formik';
import Card from '../../components/card/Card';
import { PlanListService, PlanCreateService } from '../../services/PlanService';
import HTTPService from '../../services/HTTPService';
import PlanListTable from './PlanListTable';

function CreatePlansModal({ onClose }) {
  const textColor = useColorModeValue('navy.700', 'white');

  const queryClient = useQueryClient();

  return (
    <Modal isOpen onClose={onClose} isCentered size="3xl">
      <ModalOverlay />
      <Formik
        initialValues={{ name: '', price: '' }}
        onSubmit={(values, { setFieldError }) => {
          PlanCreateService(values)
            .then((resp) => {
              if (resp.status < 300) {
                queryClient.invalidateQueries({ queryKey: ['plans'] });
                queryClient.resetQueries({ queryKey: ['plans'] });
                onClose();
              }
            }).catch((uploadError) => {
              if (uploadError.code === 'ERR_BAD_REQUEST') {
                const { data } = uploadError.response;
                Object.entries(data).forEach(([field, errors]) => {
                  setFieldError(field, errors[0]);
                });
              } else {
                setFieldError('non_field_error', 'Unknown error occurred');
              }
              // eslint-disable-next-line no-console
              console.error('Error uploading New Plan:', uploadError);
            }).finally(() => {

            });
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>

            <ModalContent>
              <ModalHeader>Add New Plan</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                display="flex"
                flexDirection="column"
              >

                <FormControl isInvalid={errors.name} pb="24px" isRequired>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Name
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Unique Name for plan"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                    />
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Price (In Rupees)
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.price}
                      name="price"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Price Value"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                    />
                  </InputGroup>

                  {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.non_field_error}>
                  {errors.non_field_error && <FormErrorMessage>{errors.non_field_error}</FormErrorMessage>}
                </FormControl>

              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" me="4px" onClick={onClose}>
                  Close
                </Button>
                <Button colorScheme="green" me="4px" type="submit" disabled={!values.name}>
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>

        )}
      </Formik>
    </Modal>
  );
}

export default function PlansPage() {
  const { ref, inView } = useInView();
  const [isAddPlanModalOpen, setAddPlanModalOpen] = useState(false);

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['plans'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? PlanListService() : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column">
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="flex-end"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            <Flex
              align="center"
              ms={{ base: '24px', md: '0px' }}
              mt={{ base: '20px', md: '0px' }}
            >
              <Button
                color={textColorBrand}
                fontWeight="500"
                onClick={() => setAddPlanModalOpen(true)}
              >
                + Add Plan
              </Button>

            </Flex>
          </Flex>
        </Flex>
        <Grid
          gap={{ base: '20px', xl: '20px' }}
        >
          <Card mb={{ base: '0px', '2xl': '20px' }}>
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              All Plans
            </Text>
            <PlanListTable
              columnsData={[
                {
                  Header: 'NAME',
                  accessor: 'name',
                },
                {
                  Header: 'PRICE',
                  accessor: 'price',
                },
                {
                  Header: 'EDIT',
                  accessor: 'edit',
                },
              ]}
              tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
            />
            <div ref={ref} />
          </Card>
        </Grid>
      </Box>
      {isAddPlanModalOpen && <CreatePlansModal onClose={() => setAddPlanModalOpen(false)} />}
    </>
  );
}
