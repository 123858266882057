import {
  Flex,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  InputGroup, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import React, { useMemo, useState } from 'react';
import {
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';

// Custom components
import {
  MdEdit, MdDeleteOutline,
} from 'react-icons/md';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import {
  GetAstroServiceByIdService,
  GetAstroQuestionsByServiceId, AstroServiceUpdateService,
} from '../../services/AstroServiceService';
// import { } from '../../services/AstroServiceService';
import Card from '../../components/card/Card';
import ImagePreview from '../../components/ImagePreview';
// Assets

function UpdateAstroServiceModal({ onClose, index: serviceId }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const queryClient = useQueryClient();
  const { isLoading, data: astroService } = useQuery(
    ['astro-services', serviceId],
    ({ queryKey }) => GetAstroServiceByIdService(queryKey[1]),
  );
  const { isLoadingQuestions, data: astroServiceQuestions } = useQuery(
    ['astro-service-question-mapping', serviceId],
    ({ queryKey }) => GetAstroQuestionsByServiceId(queryKey[1]),
  );
  if (isLoading || isLoadingQuestions) {
    return <Text>Loading...</Text>;
  }
  return (
    <Modal isOpen onClose={onClose} size="3xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>

        <Form
          onSubmit={(values, { resetForm }) => AstroServiceUpdateService({ ...values, serviceId })
            .then((resp) => {
              if (resp.status < 300) {
                queryClient.invalidateQueries({ queryKey: ['astro-service'] });
                queryClient.resetQueries({ queryKey: ['astro-service'] });
                onClose();
                resetForm();
              }
            })
            .catch(() => {
            })
            .finally(() => {})}
          initialValues={{
            name: astroService.data.name,
            price: astroService.data.price,
            thumbnail: astroService.data.thumbnail,

            astro_service_question_mapping: astroServiceQuestions.data,
          }}
          validate={(values) => {
            const errors = {};
            if (values.price < 0) {
              errors.price = 'Price can not be less than 0';
            }
            return errors;
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit, form: {
              mutators: { push },
            }, submitting, values, errors, submitError,
          }) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader>Update Astro Service</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                display="flex"
                flexDirection="column"
                overflowY="auto"
              >
                {values.thumbnail
                  && <ImagePreview image={values.thumbnail} style={{ maxHeight: '240px', width: 'auto' }} />}
                <FormControl isInvalid={errors.name} pb="24px" isRequired>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Name
                  </FormLabel>
                  <InputGroup size="md">

                    <Field name="name" isRequired>
                      {(props) => (
                        <Input
                          isRequired
                          type="text"
                          fontSize="sm"
                          placeholder="Name"
                          size="lg"
                          variant="auth"
                          value={props.input.value}
                          onChange={props.input.onChange}
                          {...props}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </FormControl>
                <FormControl isInvalid={errors.price} pb="24px" isRequired>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Price (In Rupees)
                  </FormLabel>
                  <InputGroup size="md">
                    <Field name="price" isRequired>
                      {({ input, meta }) => (
                        <>
                          <Input
                            isRequired
                            type="number"
                            fontSize="sm"
                            placeholder="Price"
                            size="lg"
                            variant="auth"
                            value={input.value}
                            onChange={input.onChange}
                          />
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>
                          )}
                        </>

                      )}
                    </Field>
                  </InputGroup>

                </FormControl>
                <FormControl isInvalid={errors.astro_service_question_mapping} pb="24px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Questions
                  </FormLabel>
                  <FieldArray name="astro_service_question_mapping">
                    {({ fields }) => fields.map((name, index) => (
                      <Flex key={index} mb="20px" minWidth="max-content" alignItems="center" gap="2">
                        <Field
                          name={`${name}`}
                          isRequired
                          key={index}
                        >
                          {(props) => (
                            <>
                              {/* {console.log(props.input)} */}
                              <Input
                                key={index}
                                isRequired
                                type="text"
                                fontSize="sm"
                                placeholder="Question"
                                size="lg"
                                variant="auth"
                                value={props.input.value.question}
                                onChange={(e) => props.input.onChange(
                                  { ...props.input.value, question: e.target.value },
                                )}
                                {...props}
                              />
                            </>
                          )}
                        </Field>
                        <Spacer />
                        <MdDeleteOutline
                          onClick={() => {
                            fields.remove(index);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Flex>
                    ))}
                  </FieldArray>
                  {errors.astro_service_question_mapping
                    && <FormErrorMessage>{errors.astro_service_question_mapping}</FormErrorMessage>}
                </FormControl>

                <div className="buttons">
                  <Button
                    colorScheme="blue"
                    me="4px"
                    onClick={() => push('astro_service_question_mapping', { question: '' })}
                  >
                    Add Question
                  </Button>
                </div>
                {submitError && <div className="error">{submitError}</div>}
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" me="4px" onClick={onClose}>
                  Close
                </Button>
                <Button colorScheme="green" me="4px" type="submit" isLoading={submitting}>
                  Update
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>

  );
}

export default function AstroServiceTable(props) {
  const { columnsData: columns, tableData } = props;
  const [isUpdateAstroServiceModelOpen, setIsUpdateAstroServiceModelOpen] = useState({ status: false, index: null });
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,

  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, cellIndex) => {
                  let cellData = '';
                  if (cell.column.Header === 'IMAGE') {
                    cellData = (
                      <img src={cell.value} style={{ height: 32, width: 'auto' }} alt="Thumbnail" />
                    );
                  }
                  if (cell.column.Header === 'NAME') {
                    cellData = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === 'PRICE') {
                    cellData = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === 'EDIT') {
                    cellData = (
                      <Button
                        variant="no-hover"
                        me="16px"
                        ms="auto"
                        p="0px !important"
                        onClick={() => {
                          setIsUpdateAstroServiceModelOpen({ status: true, index: cell.row.original.id });
                        }}
                      >
                        <Icon as={MdEdit} color="secondaryGray.500" h="18px" w="18px" />
                      </Button>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      fontSize={{ sm: '14px' }}
                      maxH="30px !important"
                      py="8px"
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {cellData}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {isUpdateAstroServiceModelOpen.status
      && (
      <UpdateAstroServiceModal
        onClose={() => setIsUpdateAstroServiceModelOpen(
          { status: false, index: null },
        )}
        index={isUpdateAstroServiceModelOpen.index}
      />
      )}
    </Card>

  );
}
