import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading, Icon,
  Input, InputGroup, InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
// Custom components
// Assets
import { Formik } from 'formik';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import illustration from '../../../assets/img/auth/auth.png';
import DefaultAuth from '../../../layouts/auth/Default';
import {
  ResetForgottenPasswordService,
  VerifyPasswordResetLinkService,
} from '../../../services/AuthService';

function ResetPasswordForm() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const toast = useToast();
  const history = useHistory();
  const [isTokenVerified, setTokenVerified] = useState(false);
  const { userId, token } = useParams();
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isConfirmNewPasswordFocused, setIsConfirmNewPasswordFocused] = useState(false);
  const [buttonColor, setButtonColor] = useState('blue');
  const [hoverButtonColor, setHoverButtonColor] = useState('brand.600');
  useEffect(() => {
    VerifyPasswordResetLinkService({ userId, token })
      .then((resp) => {
        if (resp.data.success) {
          setTokenVerified(true);
          return;
        }
        history.push('/auth/sign-in');
      });
  }, [userId, token]);

  if (!isTokenVerified) {
    return null;
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your new password
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Formik
            initialValues={{ current_password: '', new_password: '', confirm_new_password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              ResetForgottenPasswordService({ ...values, userId, token })
                .then((resp) => {
                  if (resp.status < 300) {
                    setButtonColor('green');
                    setHoverButtonColor('green.600');
                    toast({
                      title: 'Password Changed',
                      description: 'Your password has been changed. You can now use the new password to login',
                      status: 'success',
                      duration: 5000,
                      isClosable: true,
                      position: 'top-right',
                    });
                    setTimeout(() => {
                      setButtonColor('blue');
                      setHoverButtonColor('brand.600');
                      history.push('/auth/sign-in');
                    }, 5000);
                  } else {
                    setButtonColor('red');
                    setHoverButtonColor('red.600');
                    toast({
                      title: 'Error Occurred while changing password',
                      description: 'Your password has been changed. You can now use the new password to login',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                      position: 'top-right',
                    });
                  }
                }).catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                  setButtonColor('red');
                  setHoverButtonColor('red.600');
                  toast({
                    title: 'Error Occurred while changing password',
                    description: 'Your password has been changed. You can now use the new password to login',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                  });
                }).finally(() => setSubmitting(false));
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    New Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.new_password}
                      name="new_password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Min. 6 characters"
                      mb="24px"
                      size="lg"
                      // type={isNewPasswordFocused ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={isNewPasswordFocused ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={() => setIsNewPasswordFocused((prevState) => !prevState)}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Confirm New Password
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      onChange={handleChange}
                      isRequired
                      value={values.confirm_new_password}
                      name="confirm_new_password"
                      onBlur={handleBlur}
                      fontSize="sm"
                      placeholder="Min. 6 characters"
                      mb="24px"
                      size="lg"
                      type={isConfirmNewPasswordFocused ? 'text' : 'password'}
                      variant="auth"
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={isConfirmNewPasswordFocused ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={() => setIsConfirmNewPasswordFocused((prevState) => !prevState)}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    type="submit"
                    backgroundColor={buttonColor}
                    disabled={isSubmitting}
                    _hover={{ backgroundColor: hoverButtonColor }}
                    mb="24px"
                  >
                    Submit
                  </Button>
                </FormControl>
              </form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPasswordForm;
