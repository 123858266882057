import React, { useEffect, useMemo, useState } from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid, HStack,
  Icon, IconButton,
  Menu, MenuButton, MenuItem, MenuList,
  Text,
  Tag, TagLabel,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import 'react-datetime/css/react-datetime.css';
import { MdPublish, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { IoMusicalNotes, IoVideocam } from 'react-icons/io5';
import { DeleteIcon } from '@chakra-ui/icons';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';

import {
  AgeGroupListService,
  CategoryListService,
  GenderListService,
  GoalListService,
  RelationshipStatusListService,
} from '../../services/AuthService';
import {
  ContentDeleteService,
  ContentListService,
  PublishContent,
} from '../../services/ContentService';
import { TopicListService } from '../../services/TopicService';
import Card from '../../components/card/Card';
import NotificationsTable from '../notifications/NotificationsTable';

function DraftLabel({ row }) {
  return row.original.is_draft && <Tag><TagLabel>IS DRAFT</TagLabel></Tag>;
}

function PublishButton({ row, queryClient }) {
  return row.original.is_draft && (
  <Tooltip label={row.original.video.job_status !== 'complete'
    ? `This content cannot be published yet since the media processing job is in ${row.original.video.jon_status} state`
    : 'Publish'}
  >
    <span>
      <IconButton
        boxSize={6}
        isDisabled={row.original.video.job_status !== 'complete'}
        as={MdPublish}
        onClick={() => {
          PublishContent(row.original.id)
            .then(() => {
              queryClient.invalidateQueries({ queryKey: ['content'] });
              queryClient.resetQueries({ queryKey: ['content'] });
            });
        }}
      />
    </span>
  </Tooltip>
  );
}
function DeleteButton({ row, queryClient }) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<DeleteIcon />}
        variant="outline"
      />
      <MenuList>
        {row.original.is_draft && (
        <MenuItem onClick={() => ContentDeleteService(row.original.id).then(() => {
          queryClient.invalidateQueries({ queryKey: ['content'] });
          queryClient.resetQueries({ queryKey: ['content'] });
        })}
        >
          Discard Draft
        </MenuItem>
        )}
        {row.original.is_draft && row.original.parent_content && (
        <MenuItem
          onClick={() => ContentDeleteService(row.original.parent_content).then(() => {
            queryClient.invalidateQueries({ queryKey: ['content'] });
            queryClient.resetQueries({ queryKey: ['content'] });
          })}
        >
          Delete Published Version along with draft
        </MenuItem>
        )}

        {!row.original.is_draft && (
        <MenuItem
          onClick={() => ContentDeleteService(row.original.id).then(() => {
            queryClient.invalidateQueries({ queryKey: ['content'] });
            queryClient.resetQueries({ queryKey: ['content'] });
          })}
        >
          Delete Published Version
        </MenuItem>
        )}

      </MenuList>
    </Menu>
  );
}

function Image({ cell }) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img style={{ height: 32, width: 'auto' }} src={cell.value} />;
}
export default function ContentPage() {
  const { ref, inView } = useInView();
  const history = useHistory();
  const [ageMap, setAgeMap] = useState({});
  const [genderMap, setGenderMap] = useState({});
  const [goalMap, setGoalMap] = useState({});
  const [relationshipMap, setRelationshipMap] = useState({});
  const [categoryMap, setCategoryMap] = useState({});
  const [topicMap, setTopicMap] = useState({});

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['content'],
    async ({ pageParam = 0 }) => {
      const res = await ContentListService(pageParam);
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  useEffect(() => {
    AgeGroupListService()
      .then((response) => response
        .data.results.reduce((prev, item) => ({ ...prev, [item.id]: `${item.min_age} - ${item.max_age}` }), {}))
      .then(setAgeMap);
    GoalListService()
      .then((response) => response.data.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {}))
      .then(setGoalMap);

    GenderListService()
      .then((response) => response.data.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {}))
      .then(setGenderMap);

    RelationshipStatusListService()
      .then((response) => response.data.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {}))
      .then(setRelationshipMap);

    CategoryListService()
      .then((response) => response.data.results.reduce((prev, item) => ({ ...prev, [item.id]: item.name }), {}))
      .then(setCategoryMap);

    TopicListService()
      .then((response) => response.data.results.reduce((prev, item) => ({ ...prev, [item.id]: item.name }), {}))
      .then(setTopicMap);
  }, []);

  const queryClient = useQueryClient();

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const columnsData = useMemo(() => ([
    {
      Header: 'Image',
      accessor: 'thumbnail',
      Cell: Image,
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Attributes',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => (
        <HStack>
          <Icon
            transition="0.2s linear"
            w="20px"
            h="20px"
            as={row.original.video.is_audio ? IoMusicalNotes : IoVideocam}
            color="brand.500"
          />
          {row.original.is_paid && (
          <Icon
            transition="0.2s linear"
            w="20px"
            h="20px"
            as={HiOutlineCurrencyDollar}
            color="brand.500"
          />
          )}
        </HStack>
      ),
    }, {
      id: 'draft',
      Cell: DraftLabel,
    }, {
      Header: 'Age Group',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {row.original.age?.map((val) => ageMap[val]).join(', ') ?? null}
        </span>
      ),
    }, {
      Header: 'Gender',
      Cell: (props) => props.row.original.gender?.map((val) => genderMap[val]).join(', ') ?? null,
    }, {
      Header: 'Relationship Status',
      Cell: (props) => props.row.original.relationship_status?.map((val) => relationshipMap[val]).join(', ') ?? null,
    },
    {
      Header: 'Categories',
      Cell: (props) => props.row.original.category?.map((val) => categoryMap[val]).join(', ') ?? null,
    },
    {
      Header: 'Topics',
      Cell: (props) => props.row.original.topic?.map((val) => topicMap[val]).join(', ') ?? null,
    },
    {
      Header: 'Goals',
      Cell: (props) => props.row.original.goal?.map((val) => goalMap[val]).join(', ') ?? null,
    },
    {
      id: 'publish',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => <PublishButton row={row} queryClient={queryClient} />,
    },
    {
      id: 'delete',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => <DeleteButton row={row} queryClient={queryClient} />,
    },
    {
      id: 'edit',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => (
        <Tooltip label="Edit">
          <span>
            <IconButton
              boxSize={6}
              as={MdEdit}
              onClick={() => history.push(`/admin/contents/${row.original.id}`)}
            />
          </span>
        </Tooltip>
      ),
    },
  ]), [ageMap,
    genderMap,
    relationshipMap,
    categoryMap,
    topicMap,
    goalMap,
    queryClient]);

  return (
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}
        >
          <Flex
            align="center"
            ms={{ base: '24px', md: '0px' }}
            mt={{ base: '20px', md: '0px' }}
          >
            <Button
              color={textColorBrand}
              fontWeight="500"
              onClick={() => history.push('/admin/contents/new')}
            >
              + Add Content
            </Button>

          </Flex>
        </Flex>
      </Flex>
      <Grid
        gap={{ base: '20px', xl: '20px' }}
      >
        <Card mb={{ base: '0px', '2xl': '20px' }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Content
          </Text>
          <NotificationsTable
            columnsData={columnsData}
            tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
          />
          <div ref={ref} />
        </Card>
      </Grid>
    </Box>
  );
}
