import axios from 'axios';
import HTTPService from './HTTPService';
import { FileUploadUrlService } from './ContentService';

export function TopicListService() {
  return HTTPService.get('internal/api/v1/uploader/topic');
}

export function TopicCreateService({
  name, thumbnail,
}) {
  return FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: thumbnail },
    )
      .then(() => ({ url, key: fields.key })))
    .then(({ key: thumbnailKey }) => HTTPService.post('internal/api/v1/uploader/topic', {
      name, thumbnail: thumbnailKey,
    }));
}
