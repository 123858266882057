import HTTPService from './HTTPService';

export function LoginService({ email, password }) {
  return HTTPService.post('/internal/api/v1/user/login', { email, password });
}

export function LoginStatusService() {
  return HTTPService.get('/internal/api/v1/user/logged-in');
}

export function LogoutService() {
  return HTTPService.get('/internal/api/v1/user/logout');
}

export function ChangePasswordAdminService({ current_password, new_password, confirm_new_password }) {
  return HTTPService.post(
    '/internal/api/v1/user/change-password',
    { current_password, new_password, confirm_new_password },
  );
}

export function AgeGroupListService() {
  return HTTPService.get('internal/api/master-data/age-groups');
}

export function GoalListService() {
  return HTTPService.get('internal/api/master-data/goal');
}

export function GenderListService() {
  return HTTPService.get('internal/api/master-data/gender');
}

export function RelationshipStatusListService() {
  return HTTPService.get('internal/api/master-data/relationship-status');
}

export function AgeGroupUploadService({ min_age, max_age }) {
  return HTTPService.post('internal/api/master-data/age-groups', { min_age, max_age });
}

export function CategoryListService() {
  return HTTPService.get('internal/api/master-data/categories');
}

export function CategoryUploadService({ name }) {
  return HTTPService.post('internal/api/master-data/categories', { name });
}

export function SendResetLinkService({ email }) {
  return HTTPService.post('internal/api/v1/user/reset-password/email', { email });
}

export function VerifyPasswordResetLinkService({ userId, token }) {
  return HTTPService.get(`internal/api/v1/user/reset-password/${userId}/${token}/validate`);
}

export function ResetForgottenPasswordService({ userId, token, password }) {
  return HTTPService.post(`internal/api/v1/user/reset-password/${userId}/${token}/reset`, { data: { password } });
}

export function CreateAdminSerivce({
  first_name, last_name, email, phone_number, password, confirm_password,
}) {
  return HTTPService.post('internal/api/v1/user/admin', {
    first_name, last_name, email, phone_number, password, confirm_password,
  });
}

export function ListAdminService() {
  return HTTPService.get('internal/api/v1/user/admin');
}

export function ListAppUserService() {
  return HTTPService.get('internal/api/v1/user/app-users');
}

export function DeleteAstrologersService(astrologerId) {
  return HTTPService.delete(`internal/api/v1/user/astrologers/${astrologerId}`);
}

export async function CreateAstrologersService({ astro_service_mapping, ...data }) {
  const response = await HTTPService.post('internal/api/v1/user/astrologers/', data);
  const userId = response.data.id;
  await Promise.all(astro_service_mapping.map(async (id) => {
    await HTTPService.post(`internal/api/v1/astro/user/${userId}/services`, { service_id: id });
  }));
  return response;
}
