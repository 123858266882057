import AsyncSelect from 'react-select/async';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  Progress,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { MdUpload } from 'react-icons/md';
import {
  AgeGroupListService,
  CategoryListService,
  GenderListService,
  GoalListService,
  RelationshipStatusListService,
} from '../../services/AuthService';
import { PlanListService } from '../../services/PlanService';
import { TopicListService } from '../../services/TopicService';
import {
  EditContent, FileUploadUrlService, ProgressTracker, RetrieveContent,
} from '../../services/ContentService';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import VideoPreview from '../../components/VideoPreview';
import ImagePreview from '../../components/ImagePreview';

export function Select({
  isDisabled, name, placeholder, loadOptions, isMulti, value, onChange, onBlur, ...props
}) {
  return (
    <AsyncSelect
      isDisabled={isDisabled}
      name={name}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      onChange={(v) => {
        onChange(v);
      }}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
}

export function EditContentPage() {
  const history = useHistory();
  const { contentId } = useParams();
  const [initialData, setInitialData] = useState({});

  const textColor = useColorModeValue('navy.700', 'white');
  const [uploadProgress, setUploadProgress] = useState(0);

  const brandColor = useColorModeValue('brand.500', 'white');

  const loadCategoryListOptions = (inputValue) => CategoryListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: item.name,
    })));

  const loadAgeGroupsListOptions = (inputValue) => AgeGroupListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: `${item.min_age} - ${item.max_age}`,
    })));
  const loadPlanListOptions = (inputValue) => PlanListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: item.name,
    })));

  const loadTopicListOptions = (inputValue) => TopicListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: item.name,
    })));

  const loadGenderListOptions = (inputValue) => GenderListService(inputValue)
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));

  const loadGoalListOptions = () => GoalListService()
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));
  const loadRelationShipListOptions = (inputValue) => RelationshipStatusListService(inputValue)
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));

  const queryClient = useQueryClient();

  useEffect(() => {
    const promises = [
      loadAgeGroupsListOptions(),
      loadGoalListOptions(),
      loadGenderListOptions(),
      loadRelationShipListOptions(),
      loadTopicListOptions(),
      loadCategoryListOptions(),
      RetrieveContent(contentId).then((resp) => resp.data)];
    Promise.all(promises)
      .then(([ageGroupList, goalList, genderList, relationshipStatusList, topicList, categoryList, content]) => {
        const obj = { ...content };
        obj.age = ageGroupList.filter((item) => obj.age.includes(item.value));
        obj.gender = genderList.filter((item) => obj.gender.includes(item.value));
        obj.relationship_status = relationshipStatusList.filter((item) => obj.relationship_status.includes(item.value));
        obj.goal = goalList.filter((item) => obj.goal.includes(item.value));
        obj.topic = topicList.filter((item) => obj.topic.includes(item.value));
        obj.category = categoryList.filter((item) => obj.category.includes(item.value));
        setInitialData(obj);
      });
  }, [contentId]);

  if (!initialData.id) {
    return null;
  }

  return (
    <>
      <Box h={82} />
      <Box bgColor="white" p={8} borderRadius={8}>
        <Form
          initialValues={{ ...initialData, video: initialData.video.video }}
          onSubmit={(values, form) => {
            setUploadProgress(0);
            const { dirtyFields } = form.getState();
            const body = {};
            Object.keys(dirtyFields).forEach((key) => {
              if (dirtyFields[key]) {
                body[key] = values[key];
              }
            });
            const onUploadProgress = (pevt) => {
              setUploadProgress(Math.round((pevt.loaded / pevt.total) * 100));
            };
            const progressTracker = new ProgressTracker(onUploadProgress);
            let videoUploadPromise = Promise.resolve(null);
            if (body.relationship_status) {
              body.relationship_status = body.relationship_status.map(({ value }) => value);
            }
            if (body.age) {
              body.age = body.age.map(({ value }) => value);
            }
            if (body.gender) {
              body.gender = body.gender.map(({ value }) => value);
            }

            if (body.goal) {
              body.goal = body.goal.map(({ value }) => value);
            }

            if (body.topic) {
              body.topic = body.topic.map(({ value }) => value);
            }

            if (body.category) {
              body.category = body.category.map(({ value }) => value);
            }

            if (body.video) {
              videoUploadPromise = FileUploadUrlService({ name: body.video.name })
                .then(({ url, fields }) => axios.postForm(
                  url,
                  { ...fields, file: body.video },
                  { onUploadProgress: (e) => progressTracker.setProgress(1, e) },
                ).then(() => (fields.key)));
            }

            let thumbnailUploadPromise = Promise.resolve(null);
            if (body.thumbnail) {
              thumbnailUploadPromise = FileUploadUrlService({ name: body.thumbnail.name })
                .then(({ url, fields }) => axios.postForm(
                  url,
                  { ...fields, file: body.thumbnail },
                  { onUploadProgress: (e) => progressTracker.setProgress(1, e) },
                )
                  .then(() => (fields.key)));
            }

            return Promise.all([videoUploadPromise, thumbnailUploadPromise])
              .then(([videoKey, thumbnailKey]) => {
                if (videoKey) {
                  body.video = videoKey;
                }
                if (thumbnailKey) {
                  body.thumbnail = thumbnailKey;
                }
                return EditContent(contentId, { ...body, is_draft: values.is_draft });
              }).then((resp) => {
                if (resp.status < 300) {
                  queryClient.invalidateQueries({ queryKey: ['content'] });
                  queryClient.resetQueries({ queryKey: ['content'] });
                  history.push('/admin/contents');
                }
              });
          }}
          render={({
            handleSubmit,
            valid,
            dirty,
            submitting,
            form,
          }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1, overflow: 'auto' }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Field
                    name="video"
                    render={({ input }) => (
                      <div style={{ flex: 1 }}>
                        {!input.value && (
                          <AspectRatio ratio={16 / 9}>
                            <Dropzone
                              w={{ base: '100%' }}
                              me="36px"
                              maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                              flex={1}
                              minH={{ base: '0' }}
                              acceptedFileTypes={['video/*', 'audio/*']}
                              setAcceptedFiles={(files) => input.onChange(files[0])}
                              content={(
                                <Box>
                                  <Icon
                                    as={MdUpload}
                                    w="80px"
                                    h="80px"
                                    color={brandColor}
                                  />
                                  <Flex justify="center" mx="auto" mb="12px">
                                    <Text
                                      fontSize="xl"
                                      fontWeight="700"
                                      color={brandColor}
                                    >
                                      Video/Audio File
                                    </Text>
                                  </Flex>
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color="secondaryGray.500"
                                  >
                                    Only Audio/Video file allowed
                                  </Text>
                                </Box>
                                                            )}
                            />
                          </AspectRatio>
                        )}
                        {input.value && <VideoPreview video={input.value} />}
                      </div>
                    )}
                  />
                  <Field
                    name="thumbnail"
                    render={({ input }) => (
                      <div style={{ flex: 1 }}>
                        {!input.value && (
                          <AspectRatio ratio={1}>
                            <Dropzone
                              w={{ base: '100%' }}
                              me="36px"
                              maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                              flex={1}
                              minH={{ base: '0' }}
                              acceptedFileTypes={['image/*']}
                              setAcceptedFiles={(files) => input.onChange(files[0])}
                              content={(
                                <Box>
                                  <Icon
                                    as={MdUpload}
                                    w="80px"
                                    h="80px"
                                    color={brandColor}
                                  />
                                  <Flex justify="center" mx="auto" mb="12px">
                                    <Text
                                      fontSize="xl"
                                      fontWeight="700"
                                      color={brandColor}
                                    >
                                      Thumbnail
                                    </Text>
                                  </Flex>
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color="secondaryGray.500"
                                  >
                                    Image files allowed
                                  </Text>
                                </Box>
                                                            )}
                            />
                          </AspectRatio>
                        )}
                        {input.value && <ImagePreview image={input.value} />}
                      </div>
                    )}
                  />
                </div>
                <Field
                  name="title"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Title
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input}
                          isRequired
                          fontSize="sm"
                          size="lg"
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                                                && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="information"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Affirmation
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input}
                          fontSize="sm"
                          size="lg"
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="plan"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Plans
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadPlanListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                                                && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="category"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Categories (Type)
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadCategoryListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                        ? <FormErrorMessage>{meta.error}</FormErrorMessage>
                        : null}
                    </FormControl>
                  )}
                />

                <Field
                  name="topic"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Topics
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadTopicListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                        ? <FormErrorMessage>{meta.error}</FormErrorMessage>
                        : null}
                    </FormControl>
                  )}
                />
                <Field
                  name="age"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Age Groups
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadAgeGroupsListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                                                && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="relationship_status"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Relationship Status
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadRelationShipListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                                                && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="gender"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Gender
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadGenderListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                                                && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="goal"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Goal
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadGoalListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid
                                                && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="is_onboarding_audio"
                  render={({ input }) => (
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={false}
                        colorScheme="brandScheme"
                        onChange={(event) => {
                          input.onChange(event.target.checked);
                        }}
                        onBlur={input.onBlur}
                        value={input.value}
                        name="is_onboarding_audio"
                        me="10px"
                      />
                      <Text
                        color={textColor}
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                      >
                        Is Audio for onboarding?
                      </Text>
                    </Flex>
                  )}
                />

                <HStack>

                  <Field
                    name="min_score"
                    render={({ input, meta }) => (
                      <FormControl isInvalid={meta.touched && meta.invalid}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Min Score
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            {...input}
                            type="number"
                            isRequired
                            fontSize="sm"
                            size="lg"
                          />
                        </InputGroup>
                        {meta.touched && meta.invalid
                                                    && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                      </FormControl>
                    )}
                  />

                  <Field
                    name="max_score"
                    render={({ input, meta }) => (
                      <FormControl isInvalid={meta.touched && meta.invalid}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Max Score
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            {...input}
                            type="number"
                            isRequired
                            fontSize="sm"
                            size="lg"
                          />
                        </InputGroup>
                        {meta.touched && meta.invalid
                                                    && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                      </FormControl>
                    )}
                  />
                </HStack>

                {submitting && (
                  <Flex mt="16px" gap="4px" alignItems="center">
                    <Progress flex={1} hasStripe isAnimated value={uploadProgress} />
                    <Text
                      color="secondaryGray.600"
                      fontSize={{
                        base: 'sm',
                      }}
                      fontWeight="400"
                      me="14px"
                    >
                      {uploadProgress}
                      %
                    </Text>
                  </Flex>
                )}
              </div>
              <div style={{ marginTop: '24px' }}>
                <Button colorScheme="blue" me="4px" onClick={() => history.goBack('/admin/contents')}>
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting || form.getFieldState('video').dirty}
                  onClick={() => {
                    form.change('is_draft', false);
                  }}
                >
                  Save and Publish
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting}
                  onClick={() => {
                    form.change('is_draft', true);
                  }}
                >
                  Save as Draft
                </Button>
              </div>
            </form>
          )}
        />
      </Box>
    </>
  );
}
