import React, { useMemo } from 'react';

export default function VideoPreview({ video, ...props }) {
  const videoUrl = useMemo(() => {
    if (!video) {
      return undefined;
    }
    if (typeof video === 'string') {
      return video;
    }
    return URL.createObjectURL(video);
  }, [video]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video controls src={videoUrl} {...props} />;
}
