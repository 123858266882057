import React, { useState, useEffect } from 'react';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel, Grid,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  // Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
// Assets
// import { MdEdit } from 'react-icons/md';
import { useInfiniteQuery, useQueryClient, useQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import { Field, Form } from 'react-final-form';
import * as Yup from 'yup';
import { FORM_ERROR, setIn } from 'final-form';
import _ from 'lodash';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  CreateAstrologersService,
  DeleteAstrologersService,
} from '../../../../../services/AuthService';
import {
  AstroServiceList,
  ListAstrologersService,
} from '../../../../../services/AstroServiceService';
import Card from '../../../../../components/card/Card';
import HTTPService from '../../../../../services/HTTPService';
import NotificationsTable from '../../../../../admin-user-profile/notifications/NotificationsTable';
// import {  } from '../../../../../services/QuestionnaireService';
const schema = Yup.object({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone_number: Yup.string().required('Required').test(
    'is-valid-number',
    'Not a valid number.',
    (value) => isValidPhoneNumber(value, 'IN'),
  ),
});
const validate = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    return err.inner.reduce((formError, innerError) => setIn(formError, innerError.path, innerError.message), {});
  }
  return undefined;
};
function CreateAstrologerAccountModal({ onClose }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const history = useHistory();
  const queryClient = useQueryClient();
  const { isLoading, data: astroServices } = useQuery(['astro-service'], AstroServiceList);
  return (
    <Modal isOpen onClose={onClose} isCentered size="3xl">
      <ModalOverlay />
      <Form
        validate={validate}
        onSubmit={(values) => {
          CreateAstrologersService(values).then((resp) => {
            if (resp.status < 300) {
              queryClient.invalidateQueries({ queryKey: ['astrologers'] });
              queryClient.resetQueries({ queryKey: ['astrologers'] });
              history.push('/admin/astrologers');
              onClose();
            }
          })
            .catch((e) => {
              const resp = e.response;
              if (resp.status === 400) {
                const output = _.mapValues(resp.data, _.first);
                output[FORM_ERROR] = _.first(resp.data.non_field_errors);
                return output;
              }
              return { [FORM_ERROR]: 'Unknown Error Occured' };
            });
        }}
        render={({
          handleSubmit,
          pristine,
          hasValidationErrors,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>

            <ModalContent>
              <ModalHeader>Add New Account</ModalHeader>
              <ModalCloseButton />
              <ModalBody
                minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                display="flex"
                flexDirection="column"
              >

                <Field
                  name="first_name"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid} isRequired>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        First Name
                      </FormLabel>
                      <InputGroup size="md" isRequired>
                        <Input
                          isRequired
                          fontSize="sm"
                          size="lg"
                          {...input}
                        />
                      </InputGroup>

                      {errors.first_name && <FormErrorMessage>{errors.first_name}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="last_name"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid} isRequired>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Last Name
                      </FormLabel>
                      <InputGroup size="md" isRequired>
                        <Input
                          isRequired
                          fontSize="sm"
                          size="lg"
                          {...input}
                        />
                      </InputGroup>
                      {errors.last_name && <FormErrorMessage>{errors.last_name}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="phone_number"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid} isRequired>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Phone Number
                      </FormLabel>
                      <InputGroup size="md" isRequired>
                        <Input
                          isRequired
                          fontSize="sm"
                          size="lg"
                          {...input}
                        />
                      </InputGroup>
                      {errors.phone_number && <FormErrorMessage>{errors.phone_number}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="astro_service_mapping"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                        mt="24px"
                      >
                        Services
                      </FormLabel>
                      {astroServices && astroServices.data && astroServices.data.results ? (
                        <Flex direction="column">
                          {isLoading && <Text>Loading...</Text>}
                          {astroServices
                           && astroServices.data.results.length > 0
                           && astroServices.data.results.map((service) => (
                             <Checkbox
                               key={service.id}
                               name={service.name}
                               value={service.id}
                               onChange={(e) => {
                                 const isChecked = e.target.checked;
                                 const selectedServiceId = service.id;
                                 const updatedSelectedServices = isChecked
                                   ? [...input.value, selectedServiceId]
                                   : input.value.filter((id) => id !== selectedServiceId);
                                 input.onChange(updatedSelectedServices);
                               }}
                               checked={input.value.includes(service.id)}
                             >
                               {service.name}
                             </Checkbox>
                           ))}
                        </Flex>
                      ) : (
                        <Text>Loading...</Text>
                      )}
                      {meta.touched && meta.invalid
                          && <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" me="4px" onClick={onClose}>
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={pristine || hasValidationErrors}
                >
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>

        )}
      />
    </Modal>
  );
}

function DeleteButton({ row, queryClient }) {
  return (
    <IconButton
      icon={<DeleteIcon />}
      colorScheme="red"
      variant="solid"
      onClick={() => {
        DeleteAstrologersService(row.original.id).then(() => {
          queryClient.invalidateQueries({ queryKey: ['astrologers'] });
          queryClient.resetQueries({ queryKey: ['astrologers'] });
        });
      }}
    />
  );
}

function AstrologerAccounts() {
  const { ref, inView } = useInView();
  const [isAddAccountModalOpen, setAddAccountModalOpen] = useState(false);
  // const history = useHistory();
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const {
    data,
    isFetching,
    fetchNextPage,
    queryClient,
  } = useInfiniteQuery(
    ['astrologers'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? ListAstrologersService() : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column">
          <Flex
            mt="45px"
            mb="20px"
            justifyContent="flex-end"
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
          >
            <Flex
              align="center"
              ms={{ base: '24px', md: '0px' }}
              mt={{ base: '20px', md: '0px' }}
            >
              <Button
                color={textColorBrand}
                fontWeight="500"
                onClick={() => setAddAccountModalOpen(true)}
              >
                + Add Account
              </Button>

            </Flex>
          </Flex>
        </Flex>
        <Grid
          gap={{ base: '20px', xl: '20px' }}
        >
          <Card mb={{ base: '0px', '2xl': '20px' }}>
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              Astrologers
            </Text>
            <NotificationsTable
              columnsData={[
                {
                  Header: 'First Name',
                  accessor: 'first_name',
                },
                {
                  Header: 'Last Name',
                  accessor: 'last_name',
                },
                {
                  Header: 'PHONE NUMBER',
                  accessor: 'phone_number',
                },
                {
                  id: 'delete',
                  // eslint-disable-next-line react/no-unstable-nested-components
                  Cell: ({ row }) => <DeleteButton row={row} queryClient={queryClient} />,
                },
              ]}
              tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
            />
            <div ref={ref} />
          </Card>
        </Grid>
      </Box>

      {isAddAccountModalOpen && <CreateAstrologerAccountModal onClose={() => setAddAccountModalOpen(false)} />}
    </>
  );
}

export default AstrologerAccounts;
