import axios from 'axios';
import HTTPService from './HTTPService';
import { FileUploadUrlService, ProgressTracker } from './ContentService';

export function SeriesListService() {
  return HTTPService.get('internal/api/v1/uploader/series');
}

export function EpisodesListService({ seriesId }) {
  return HTTPService.get(`internal/api/v1/uploader/series/${seriesId}/episode`);
}

export function SeriesRetrieveService({ seriesId }) {
  return HTTPService.get(`internal/api/v1/uploader/series/${seriesId}`);
}

export function EpisodeRetrieveService({ seriesId, episodeId }) {
  return HTTPService.get(`internal/api/v1/uploader/series/${seriesId}/episode/${episodeId}`);
}

export function SeriesDeleteService(seriesId) {
  return HTTPService.delete(`internal/api/v1/uploader/series/${seriesId}`);
}

export function EpisodeDeleteService(seriesId, episodeId) {
  return HTTPService.delete(`internal/api/v1/uploader/series/${seriesId}/episode/${episodeId}`);
}

export function EpisodeCreateService({
  episode_id, series, video, title, onUploadProgress, thumbnail, is_draft, information,
}) {
  const progressTracker = new ProgressTracker(onUploadProgress);

  const videoUploadPromise = FileUploadUrlService({ name: video.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: video },
      { onUploadProgress: (e) => progressTracker.setProgress(1, e) },
    )
      .then(() => ({ url, key: fields.key })));
  const thumbnailUploadPromise = FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: thumbnail },
      { onUploadProgress: (e) => progressTracker.setProgress(2, e) },
    )
      .then(() => ({ url, key: fields.key })));
  return Promise.all([videoUploadPromise, thumbnailUploadPromise])
    .then(([{ key: videoKey },
      { key: thumbnailKey }]) => HTTPService.post(`internal/api/v1/uploader/series/${series}/episode`, {
      episode_id, video: videoKey, title, thumbnail: thumbnailKey, is_draft, information,
    }));
}

export function PublishSeries(seriesId) {
  return HTTPService.post(`internal/api/v1/uploader/series/${seriesId}/publish`);
}

export function SeriesCreateService({
  title, start_date, end_date, thumbnail, topic_ids, plan_ids, category_ids, age_group_ids, relationship_status_ids,
  gender, goal, occupation, education, is_draft,
}) {
  return FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: thumbnail },
    )
      .then(() => ({ url, key: fields.key })))
    .then(({ key: thumbnailKey }) => HTTPService.post('internal/api/v1/uploader/series', {
      title,
      start_date,
      end_date,
      thumbnail: thumbnailKey,
      topic_ids,
      plan_ids,
      category_ids,
      age_group_ids,
      relationship_status_ids,
      gender,
      goal,
      occupation,
      education,
    }))
    .then((resp) => {
      if (!is_draft) {
        return PublishSeries(resp.data.id);
      }
      return resp;
    });
}

export function EditSeries(seriesId, { is_draft, ...data }) {
  return HTTPService.patch(`internal/api/v1/uploader/series/${seriesId}`, data)
    .then((resp) => {
      if (!is_draft) {
        return PublishSeries(resp.data.id);
      }
      return resp;
    });
}

export function PublishEpisode(seriesId, episodeId) {
  return HTTPService.post(`internal/api/v1/uploader/series/${seriesId}/episode/${episodeId}/publish`);
}

export function EditEpisode(seriesId, episodeId, { is_draft, ...data }) {
  return HTTPService.patch(`internal/api/v1/uploader/series/${seriesId}/episode/${episodeId}`, data)
    .then((resp) => {
      if (!is_draft) {
        return PublishEpisode(resp.data.id);
      }
      return resp;
    });
}
