import axios from 'axios';
import HTTPService from './HTTPService';

export function PublishContent(contentId) {
  return HTTPService.post(`internal/api/v1/uploader/content/${contentId}/publish`);
}

export class ProgressTracker {
  constructor(uploadHandler) {
    this.uploadHandler = uploadHandler;
    this.progress = new Map();
  }

  finalProgress() {
    const progressToReport = { loaded: 0, total: 0, rate: 0 };

    // eslint-disable-next-line no-restricted-syntax
    for (const progress of this.progress.values()) {
      progressToReport.loaded += progress.loaded;
      progressToReport.total += progress.total;
    }
    return progressToReport;
  }

  setProgress(id, progress) {
    this.progress.set(id, progress);
    this.uploadHandler(this.finalProgress());
  }
}

export function ContentListService() {
  return HTTPService.get('internal/api/v1/uploader/content');
}

export function ContentRetrieveService({ id }) {
  return HTTPService.get(`internal/api/v1/uploader/content/${id}`);
}

export function ContentDeleteService(id) {
  return HTTPService.delete(`internal/api/v1/uploader/content/${id}`);
}

export function MediaListService() {
  return HTTPService.get('internal/api/v1/uploader/file-upload/?job_status=complete&has_content=true');
}

export function FileUploadUrlService({ name }) {
  return HTTPService.post('internal/api/v1/uploader/file-upload', { name })
    .then((resp) => resp.data);
}

export function ContentUploadService({
  video, plan_ids, category_ids, age_group_ids, title, is_draft, topic_ids, onUploadProgress, thumbnail,
  relationship_status_ids, gender, goal, is_onboarding_audio, min_score, max_score, information,
}) {
  const progressTracker = new ProgressTracker(onUploadProgress);
  const videoUploadPromise = FileUploadUrlService({ name: video.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: video },
      { onUploadProgress: (e) => progressTracker.setProgress(1, e) },
    )
      .then(() => ({ url, key: fields.key })));
  const thumbnailUploadPromise = FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios.postForm(
      url,
      { ...fields, file: thumbnail },
      { onUploadProgress: (e) => progressTracker.setProgress(2, e) },
    )
      .then(() => ({ url, key: fields.key })));
  return Promise.all([videoUploadPromise, thumbnailUploadPromise])
    .then(([{ key: videoKey },
      { key: thumbnailKey }]) => HTTPService.post('internal/api/v1/uploader/content', {
      video: videoKey,
      is_paid: plan_ids.length !== 0,
      plan_ids,
      is_personalized: false,
      user_ids: [],
      category_ids,
      topic_ids,
      age_group_ids,
      title,
      relationship_status_ids,
      gender,
      is_onboarding_audio,
      goal,
      thumbnail: thumbnailKey,
      min_score,
      max_score,
      information,
    }, {
      onUploadProgress,
    }))
    .then((resp) => {
      if (!is_draft) {
        return PublishContent(resp.data.id);
      }
      return resp;
    });
}

export function AppUsersListService() {
  return HTTPService.get('internal/api/v1/user/app-users');
}

export function RetrieveContent(contentId) {
  return HTTPService.get(`internal/api/v1/uploader/content/${contentId}`);
}

export function EditContent(contentId, { is_draft, ...data }) {
  return HTTPService.patch(`internal/api/v1/uploader/content/${contentId}`, data)
    .then((resp) => {
      if (!is_draft && resp.data.video.job_status === 'complete') {
        return PublishContent(resp.data.id);
      }
      return resp;
    });
}
