import axios from 'axios';
import HTTPService from './HTTPService';
import { FileUploadUrlService, ProgressTracker } from './ContentService';

export function CourseListService() {
  return HTTPService.get('internal/api/v1/courses/courses');
}

export function VideosListService({ courseId }) {
  return HTTPService.get(`internal/api/v1/courses/courses/${courseId}/video`);
}

export function CourseRetrieveService({ courseId }) {
  return HTTPService.get(`internal/api/v1/courses/courses/${courseId}`);
}

export function VideoRetrieveService({ courseId, order_id }) {
  return HTTPService.get(
    `internal/api/v1/courses/courses/${courseId}/video/${order_id}`,
  );
}

export function CourseDeleteService(courseId) {
  return HTTPService.delete(`internal/api/v1/courses/courses/${courseId}`);
}

export function VideoDeleteService(courseId, order_id) {
  return HTTPService.delete(
    `internal/api/v1/courses/courses/${courseId}/video/${order_id}`,
  );
}

export function VideoCreateService({
  order_id,
  course,
  video,
  title,
  onUploadProgress,
  thumbnail,
  is_draft,
  information,
}) {
  const progressTracker = new ProgressTracker(onUploadProgress);

  const videoUploadPromise = FileUploadUrlService({ name: video.name }).then(
    ({ url, fields }) => axios
      .postForm(
        url,
        { ...fields, file: video },
        { onUploadProgress: (e) => progressTracker.setProgress(1, e) },
      )
      .then(() => ({ url, key: fields.key })),
  );
  const thumbnailUploadPromise = FileUploadUrlService({
    name: thumbnail.name,
  }).then(({ url, fields }) => axios
    .postForm(
      url,
      { ...fields, file: thumbnail },
      { onUploadProgress: (e) => progressTracker.setProgress(2, e) },
    )
    .then(() => ({ url, key: fields.key })));
  return Promise.all([videoUploadPromise, thumbnailUploadPromise]).then(
    // eslint-disable-next-line max-len
    ([{ key: videoKey }, { key: thumbnailKey }]) => HTTPService.post(`internal/api/v1/courses/courses/${course}/video`, {
      order_id,
      video: videoKey,
      title,
      thumbnail: thumbnailKey,
      is_draft,
      information,
    }),
  );
}

export function PublishCourse(courseId) {
  return HTTPService.post(
    `internal/api/v1/courses/courses/${courseId}/publish`,
  );
}

export function CourseCreateService({
  title,
  thumbnail,
  price,
  description,
  is_draft,
}) {
  return FileUploadUrlService({ name: thumbnail.name })
    .then(({ url, fields }) => axios
      .postForm(url, { ...fields, file: thumbnail })
      .then(() => ({ url, key: fields.key })))
    .then(({ key: thumbnailKey }) => HTTPService.post('internal/api/v1/courses/courses', {
      title,
      thumbnail: thumbnailKey,
      description,
      price,
    }))
    .then((resp) => {
      if (!is_draft) {
        return PublishCourse(resp.data.id);
      }
      return resp;
    });
}

export function EditCourse(courseId, { is_draft, ...data }) {
  return HTTPService.patch(
    `internal/api/v1/courses/courses/${courseId}`,
    data,
  ).then((resp) => {
    if (!is_draft) {
      return PublishCourse(resp.data.id);
    }
    return resp;
  });
}

export function PublishVideo(courseId, order_id) {
  return HTTPService.post(
    `internal/api/v1/courses/courses/${courseId}/video/${order_id}/publish`,
  );
}

export function EditVideo(courseId, order_id, { is_draft, ...data }) {
  return HTTPService.patch(
    `internal/api/v1/courses/courses/${courseId}/video/${order_id}`,
    data,
  ).then((resp) => {
    if (!is_draft) {
      return PublishVideo(courseId, resp.data.id);
    }
    return resp;
  });
}
