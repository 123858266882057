import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import { MdEdit, MdPublish } from 'react-icons/md';
import Card from '../../components/card/Card';
import HTTPService from '../../services/HTTPService';
import QuestionnairesTable from './QuestionnairesTable';
import { DeleteQuestionnaire, ListQuestionnaire, PublishQuestionnaire } from '../../services/QuestionnaireService';
import {
  AgeGroupListService,
  GenderListService,
  GoalListService,
  RelationshipStatusListService,
} from '../../services/AuthService';

function DraftLabel({ row }) {
  return row.original.is_draft && <Tag><TagLabel>IS DRAFT</TagLabel></Tag>;
}

function DeleteButton({ row, queryClient }) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<DeleteIcon />}
        variant="outline"
      />
      <MenuList>
        {row.original.is_draft && (
        <MenuItem onClick={() => DeleteQuestionnaire(row.original.id).then(() => {
          queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
          queryClient.resetQueries({ queryKey: ['questionnaire'] });
        })}
        >
          Discard Draft
        </MenuItem>
        )}
        {row.original.is_draft && row.original.parent_questionnaire && (
        <MenuItem
          onClick={() => DeleteQuestionnaire(row.original.parent_questionnaire).then(() => {
            queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
            queryClient.resetQueries({ queryKey: ['questionnaire'] });
          })}
        >
          Delete Published Version along with draft
        </MenuItem>
        )}

        {!row.original.is_draft && (
        <MenuItem
          onClick={() => DeleteQuestionnaire(row.original.id).then(() => {
            queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
            queryClient.resetQueries({ queryKey: ['questionnaire'] });
          })}
        >
          Delete Published Version
        </MenuItem>
        )}

      </MenuList>
    </Menu>
  );
}

function PublishButton({ row, queryClient }) {
  return row.original.is_draft && (
  <Tooltip label="Publish">
    <span>
      <IconButton
        boxSize={6}
        as={MdPublish}
        onClick={() => {
          PublishQuestionnaire(row.original.id)
            .then(() => {
              queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
              queryClient.resetQueries({ queryKey: ['questionnaire'] });
            });
        }}
      />
    </span>
  </Tooltip>
  );
}

export default function QuestionnairesPage() {
  const { ref, inView } = useInView();
  const [ageMap, setAgeMap] = useState({});
  const [genderMap, setGenderMap] = useState({});
  const [goalMap, setGoalMap] = useState({});
  const [relationshipMap, setRelationshipMap] = useState({});
  const history = useHistory();

  const {
    data,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    ['questionnaire'],
    async ({ pageParam = 0 }) => {
      const res = await (pageParam === 0 ? ListQuestionnaire() : HTTPService.get(pageParam));
      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previous ?? undefined,
      getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    },
  );
  const queryClient = useQueryClient();

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNextPage();
    }
  }, [inView, isFetching]);
  const textColorBrand = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    AgeGroupListService()
      .then((response) => response
        .data.results.reduce((prev, item) => ({ ...prev, [item.id]: `${item.min_age} - ${item.max_age}` }), {}))
      .then(setAgeMap);
    GoalListService()
      .then((response) => response.data.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {}))
      .then(setGoalMap);

    GenderListService()
      .then((response) => response.data.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {}))
      .then(setGenderMap);

    RelationshipStatusListService()
      .then((response) => response.data.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {}))
      .then(setRelationshipMap);
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="flex-end"
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}
        >
          <Flex
            align="center"
            ms={{ base: '24px', md: '0px' }}
            mt={{ base: '20px', md: '0px' }}
          >
            <Button
              color={textColorBrand}
              fontWeight="500"
              onClick={() => history.push('/admin/questionnaire/new')}
            >
              + New Question
            </Button>

          </Flex>
        </Flex>
      </Flex>
      <Grid
        gap={{ base: '20px', xl: '20px' }}
      >
        <Card mb={{ base: '0px', '2xl': '20px' }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Questionnaire
          </Text>
          <QuestionnairesTable
            columnsData={[
              {
                Header: 'Questions',
                accessor: 'question',
              },
              {
                Header: 'Age Group',
                Cell: (props) => props.row.original.age_group.map((val) => ageMap[val]).join(', '),
              }, {
                Header: 'Gender',
                Cell: (props) => props.row.original.gender.map((val) => genderMap[val]).join(', '),
              }, {
                Header: 'Relationship Status',
                Cell: (props) => props.row.original.relationship_status.map((val) => relationshipMap[val]).join(', '),
              },
              {
                Header: 'Goal',
                Cell: (props) => props.row.original.goal.map((val) => goalMap[val]).join(', '),
              },
              {
                id: 'draft',
                Cell: DraftLabel,
              }, {
                id: 'delete',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ row }) => <DeleteButton row={row} queryClient={queryClient} />,
              },
              {
                id: 'publish',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ row }) => <PublishButton row={row} queryClient={queryClient} />,
              },
              {
                id: 'edit',
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ row }) => (
                  <Tooltip label="Edit">
                    <span>
                      <IconButton
                        boxSize={6}
                        as={MdEdit}
                        onClick={() => history.push(`/admin/questionnaire/${row.original.id}`)}
                      />
                    </span>
                  </Tooltip>
                ),
              },
            ]}
            tableData={data ? data.pages.reduce((previous, item) => previous.concat(item.results), []) : []}
          />
          <div ref={ref} />
        </Card>
      </Grid>
    </Box>
  );
}
