import React from 'react';

// Chakra imports
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import { useQueryClient } from '@tanstack/react-query';
import 'react-datetime/css/react-datetime.css';
import { MdUpload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AsyncSelect from 'react-select/async';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import ImagePreview from '../../components/ImagePreview';
import { CourseCreateService } from '../../services/CoursesService';

export function Select({
  isDisabled,
  name,
  placeholder,
  loadOptions,
  isMulti,
  value,
  onChange,
  onBlur,
  ...props
}) {
  return (
    <AsyncSelect
      isDisabled={isDisabled}
      name={name}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      onChange={(v) => {
        onChange(v);
      }}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
}

export function NewCoursePage() {
  // TODO: Add yup validations
  const history = useHistory();

  const textColor = useColorModeValue('navy.700', 'white');

  const brandColor = useColorModeValue('brand.500', 'white');

  const queryClient = useQueryClient();

  return (
    <>
      <Box h={82} />
      <Box bgColor="white" p={8} borderRadius={8}>
        <Form
          initialValues={{
            title: null,
            thumbnail: null,
            description: null,
            is_draft: true,
          }}
          onSubmit={(values) => CourseCreateService({
            ...values,
          }).then((resp) => {
            if (resp.status < 300) {
              queryClient.invalidateQueries({ queryKey: ['course'] });
              queryClient.resetQueries({ queryKey: ['course'] });
              history.push('/admin/courses');
            }
          })}
          render={({
            handleSubmit, valid, dirty, submitting, form,
          }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1, overflow: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Field
                    name="thumbnail"
                    render={({ input }) => (
                      <div style={{ flex: 1, maxWidth: '500px' }}>
                        {!input.value && (
                          <AspectRatio ratio={1}>
                            <Dropzone
                              w={{ base: '100%' }}
                              me="36px"
                              maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                              flex={1}
                              minH={{ base: '0' }}
                              acceptedFileTypes={['image/*']}
                              setAcceptedFiles={(files) => input.onChange(files[0])}
                              content={(
                                <Box>
                                  <Icon
                                    as={MdUpload}
                                    w="80px"
                                    h="80px"
                                    color={brandColor}
                                  />
                                  <Flex justify="center" mx="auto" mb="12px">
                                    <Text
                                      fontSize="xl"
                                      fontWeight="700"
                                      color={brandColor}
                                    >
                                      Thumbnail
                                    </Text>
                                  </Flex>
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color="secondaryGray.500"
                                  >
                                    Image files allowed
                                  </Text>
                                </Box>
                              )}
                            />
                          </AspectRatio>
                        )}
                        {input.value && <ImagePreview image={input.value} />}
                      </div>
                    )}
                  />
                </div>
                <Field
                  name="title"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Title
                      </FormLabel>
                      <InputGroup size="md">
                        <Input {...input} isRequired fontSize="sm" size="lg" />
                      </InputGroup>
                      {meta.touched && meta.invalid && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <Field
                  name="price"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Price
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input}
                          type="number"
                          fontSize="sm"
                          size="lg"
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <Field
                  name="description"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Description
                      </FormLabel>
                      <InputGroup size="md">
                        <Input {...input} isRequired fontSize="sm" size="lg" />
                      </InputGroup>
                      {meta.touched && meta.invalid && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
              </div>
              <div style={{ marginTop: '24px' }}>
                <Button
                  colorScheme="blue"
                  me="4px"
                  onClick={() => history.goBack('/admin/courses')}
                >
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting}
                  onClick={() => {
                    form.change('is_draft', true);
                  }}
                >
                  Save as Draft
                </Button>
              </div>
            </form>
          )}
        />
      </Box>
    </>
  );
}
