import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import theme from './theme/theme';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import { LoginStatusService } from './services/AuthService';
import CurrentUserStore from './stores/CurrentUserStore';

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    LoginStatusService()
      .then((resp) => {
        if (resp.status === 200 && resp.data.status) {
          CurrentUserStore.setLoginStatus(true, resp.data.user_data);
          return;
        }
        CurrentUserStore.setLoginStatus(false, undefined);
      });
  }, []);
  if (!CurrentUserStore.isHydrated) {
    return null;
  }
  return (
    <React.StrictMode>
      <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right' } }}>

        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Switch>
              {!CurrentUserStore.isLoggedIn && <Route path="/auth" component={AuthLayout} />}
              {CurrentUserStore.isLoggedIn && <Route path="/admin" component={AdminLayout} />}
              <Redirect from="/" to={CurrentUserStore.isLoggedIn ? '/admin' : '/auth/sign-in'} />
            </Switch>
          </BrowserRouter>
        </QueryClientProvider>
      </ChakraProvider>
    </React.StrictMode>
  );
}
const ObservedApp = observer(App);
ReactDOM.render(
  <ObservedApp />,
  document.getElementById('root'),
);
