import React, { useState } from 'react';

// Chakra imports
import {
  AspectRatio,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  Progress,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import { useQueryClient } from '@tanstack/react-query';
import 'react-datetime/css/react-datetime.css';
import { MdUpload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import {
  AgeGroupListService,
  CategoryListService,
  GenderListService,
  GoalListService,
  RelationshipStatusListService,
} from '../../services/AuthService';
import { PlanListService } from '../../services/PlanService';
import { ContentUploadService } from '../../services/ContentService';
import Dropzone from '../../views/admin/profile/components/Dropzone';
import VideoPreview from '../../components/VideoPreview';
import ImagePreview from '../../components/ImagePreview';
import { TopicListService } from '../../services/TopicService';
import { Select } from './EditContentPage';

function addYears(date, years) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}
export function NewContentPage() {
  // TODO: Add yup validations
  const history = useHistory();

  const textColor = useColorModeValue('navy.700', 'white');
  const [uploadProgress, setUploadProgress] = useState(0);

  const brandColor = useColorModeValue('brand.500', 'white');

  const loadCategoryListOptions = (inputValue) => CategoryListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: item.name,
    })));

  const loadAgeGroupsListOptions = (inputValue) => AgeGroupListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: `${item.min_age} - ${item.max_age}`,
    })));
  const loadPlanListOptions = (inputValue) => PlanListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: item.name,
    })));

  const loadTopicListOptions = (inputValue) => TopicListService(inputValue)
    .then((response) => response.data.results.map((item) => ({
      value: item.id,
      label: item.name,
    })));

  const loadGenderListOptions = (inputValue) => GenderListService(inputValue)
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));

  const loadGoalListOptions = () => GoalListService()
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));
  const loadRelationShipListOptions = (inputValue) => RelationshipStatusListService(inputValue)
    .then((response) => response.data.map((item) => ({
      value: item.id,
      label: item.label,
    })));

  const queryClient = useQueryClient();

  return (
    <>
      <Box h={82} />
      <Box bgColor="white" p={8} borderRadius={8}>
        <Form
          initialValues={{
            video: null,
            title: null,
            thumbnail: null,
            plan_ids: [],
            topic_ids: [],
            category_ids: [],
            age_group_ids: [],
            is_onboarding_audio: false,
            relationship_status_ids: [],
            gender: [],
            information: '',
            min_score: 1,
            max_score: 5,
            goal: [],
            start_date: new Date(),
            end_date: addYears(new Date(), 100),
            is_draft: true,
          }}
          onSubmit={(values) => ContentUploadService({
            ...values,
            onUploadProgress: (pevt) => {
              setUploadProgress(Math.round((pevt.loaded / pevt.total) * 100));
            },
            plan_ids: values.plan_ids.map((option) => option.value),
            topic_ids: values.topic_ids.map((option) => option.value),
            category_ids: values.category_ids.map((option) => option.value),
            age_group_ids: values.age_group_ids.map((option) => option.value),
            relationship_status_ids: values.relationship_status_ids.map((option) => option.value),
            gender: values.gender.map((option) => option.value),
            goal: values.goal.map((option) => option.value),
          }).then((resp) => {
            if (resp.status < 300) {
              queryClient.invalidateQueries({ queryKey: ['content'] });
              queryClient.resetQueries({ queryKey: ['content'] });
              history.push('/admin/contents');
            }
          })}
          render={({
            handleSubmit,
            valid,
            dirty,
            submitting,
            form,
          }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1, overflow: 'auto' }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Field
                    name="video"
                    render={({ input }) => (
                      <div style={{ flex: 1 }}>
                        {!input.value && (
                        <AspectRatio ratio={16 / 9}>
                          <Dropzone
                            w={{ base: '100%' }}
                            me="36px"
                            maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                            flex={1}
                            minH={{ base: '0' }}
                            acceptedFileTypes={['video/*', 'audio/*']}
                            setAcceptedFiles={(files) => input.onChange(files[0])}
                            content={(
                              <Box>
                                <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                                <Flex justify="center" mx="auto" mb="12px">
                                  <Text fontSize="xl" fontWeight="700" color={brandColor}>
                                    Video/Audio File
                                  </Text>
                                </Flex>
                                <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                                  Only Audio/Video file allowed
                                </Text>
                              </Box>
                                            )}
                          />
                        </AspectRatio>
                        )}
                        {input.value && <VideoPreview video={input.value} />}
                      </div>
                    )}
                  />
                  <Field
                    name="thumbnail"
                    render={({ input }) => (
                      <div style={{ flex: 1 }}>
                        {!input.value && (
                          <AspectRatio ratio={1}>
                            <Dropzone
                              w={{ base: '100%' }}
                              me="36px"
                              maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
                              flex={1}
                              minH={{ base: '0' }}
                              acceptedFileTypes={['image/*']}
                              setAcceptedFiles={(files) => input.onChange(files[0])}
                              content={(
                                <Box>
                                  <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                                  <Flex justify="center" mx="auto" mb="12px">
                                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                                      Thumbnail
                                    </Text>
                                  </Flex>
                                  <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                                    Image files allowed
                                  </Text>
                                </Box>
                                            )}
                            />
                          </AspectRatio>
                        )}
                        {input.value && <ImagePreview image={input.value} />}
                      </div>
                    )}
                  />
                </div>
                <Field
                  name="title"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Title
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input}
                          isRequired
                          fontSize="sm"
                          size="lg"
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="information"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Affirmation
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input}
                          fontSize="sm"
                          size="lg"
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="plan_ids"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Plans
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadPlanListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="category_ids"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Categories (Type)
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadCategoryListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid ? <FormErrorMessage>{meta.error}</FormErrorMessage>
                        : null}
                    </FormControl>
                  )}
                />

                <Field
                  name="topic_ids"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Topics
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadTopicListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid ? <FormErrorMessage>{meta.error}</FormErrorMessage>
                        : null}
                    </FormControl>
                  )}
                />
                <Field
                  name="age_group_ids"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Age Groups
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadAgeGroupsListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="relationship_status_ids"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Relationship Status
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadRelationShipListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Field
                  name="gender"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Gender
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadGenderListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="goal"
                  render={({ input, meta }) => (
                    <FormControl isInvalid={meta.touched && meta.invalid}>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        Goal
                      </FormLabel>
                      <InputGroup size="md">
                        <Select
                          isMulti
                          isDisabled={false}
                          loadOptions={loadGoalListOptions}
                          {...input}
                          styles={{
                            container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
                          }}
                        />
                      </InputGroup>
                      {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
                <Field
                  name="is_onboarding_audio"
                  render={({ input }) => (
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={false}
                        colorScheme="brandScheme"
                        onChange={(event) => {
                          input.onChange(event.target.checked);
                        }}
                        onBlur={input.onBlur}
                        value={input.value}
                        name="is_onboarding_audio"
                        me="10px"
                      />
                      <Text
                        color={textColor}
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                      >
                        Is Audio for onboarding?
                      </Text>
                    </Flex>
                  )}
                />

                <HStack>

                  <Field
                    name="min_score"
                    render={({ input, meta }) => (
                      <FormControl isInvalid={meta.touched && meta.invalid}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Min Score
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            {...input}
                            type="number"
                            isRequired
                            fontSize="sm"
                            size="lg"
                          />
                        </InputGroup>
                        {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                      </FormControl>
                    )}
                  />

                  <Field
                    name="max_score"
                    render={({ input, meta }) => (
                      <FormControl isInvalid={meta.touched && meta.invalid}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Max Score
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            {...input}
                            type="number"
                            isRequired
                            fontSize="sm"
                            size="lg"
                          />
                        </InputGroup>
                        {meta.touched && meta.invalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
                      </FormControl>
                    )}
                  />
                </HStack>

                {submitting && (
                  <Flex mt="16px" gap="4px" alignItems="center">
                    <Progress flex={1} hasStripe isAnimated value={uploadProgress} />
                    <Text
                      color="secondaryGray.600"
                      fontSize={{
                        base: 'sm',
                      }}
                      fontWeight="400"
                      me="14px"
                    >
                      {uploadProgress}
                      %
                    </Text>
                  </Flex>
                )}
              </div>
              <div style={{ marginTop: '24px' }}>
                <Button colorScheme="blue" me="4px" onClick={() => history.goBack('/admin/contents')}>
                  Close
                </Button>
                <Button
                  colorScheme="green"
                  me="4px"
                  type="submit"
                  disabled={!dirty || !valid || submitting}
                  onClick={() => {
                    form.change('is_draft', true);
                  }}
                >
                  Save as Draft
                </Button>
              </div>
            </form>
          )}
        />
      </Box>
    </>
  );
}
